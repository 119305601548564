import style from './not_found.module.scss'

const NotFound = () => {
    return (
        <div className={style.container}>
            <h4>Страница в разработке</h4>
            <img src="/404.png" alt="404 not found"/>
        </div>
    );
};

export default NotFound;