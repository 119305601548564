import axios from 'axios'
import {getCookie} from "./autovizitApi"


const baseURL = process.env.REACT_APP_HTTP_SERVER

export function deleteCookie(name) {
    document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const instanceServerApi = axios.create({
    baseURL: baseURL,
    timeout: 1200000
});

// const token = useSelector(state => state.user.token.accessToken)

instanceServerApi.interceptors.request.use(
    async config => {
        const value  = getCookie('accessToken')
        config.headers = {
            'Authorization': `Bearer ${value}`,
            'X-Frame-Options': 'SAMEORIGIN'
        }
        return config;
        
    },
    error => {
        Promise.reject(error)
    });

instanceServerApi.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
        deleteCookie('accessToken')
        originalRequest._retry = true;
        return Promise.reject(error);
    }
    console.log("Неожиданная ошибка")
    return Promise.reject(error);
});

export default instanceServerApi