import React, {createContext, useState,  useEffect, useContext} from 'react';
import {AlertContext} from "./AlertContext";
import downloadExcel from "../lib/utils/excel/downloadExcel";

// eslint-disable-next-line import/no-webpack-loader-syntax
import worker from "workerize-loader!../workers/excel.js";
import {ReactSVG} from "react-svg";
import style from './context_component.module.scss'
export const ExportContext = createContext({})

function ExportExcelProvider({children}) {

    const {openAlert,closeAlert} = useContext(AlertContext)
    const [exportWorker, setWorker] = useState(null)

    useEffect(()=>{
        const work = new worker()
        setWorker(work);

        return(()=> {
          work.terminate()
        })
    },[])

    const createExcel =  (msg,data,date,config,title,date_items) =>{
        openAlert()
        exportWorker
            .postMessage({msg: msg,
                data: data,
                date:  date,
                config: config,
                title: title,
                date_items:date_items
            });
    }

    useEffect(() => {
        if(exportWorker) {
            exportWorker.onmessage = (event) => {
                if (event && event.data && event.data.msg === "download") {
                    downloadExcel(event.data.data,closeAlert,event.data.title);
                }
            };
        }
    }, [exportWorker]);


    return (
        <ExportContext.Provider value={{exportWorker,createExcel}}>
            {children}
        </ExportContext.Provider>
    );
}

export default ExportExcelProvider;


export const ExportSVGExcel = ({onClick}) =>{
    return (
        <ReactSVG  className={style.svg_excel} src={'/icons/ico_excel.svg'} onClick={ onClick }/>
    )
}