import {useContext} from 'react';
import {UserContext} from "../../context/UserContext"
import {Redirect, Route} from "react-router-dom"
import {PATHES} from "../../constants/RoutesPath";


const PrivateRoleRoute = ({component: Component, role: role = "", ...rest}) => {
    const {loginUser} = useContext(UserContext)
    if (loginUser === true) {
        return (
            <Route  {...rest} render={props => (
                <Component {...props} />
            )
            }/>
        );
    }
    if (!loginUser) {
        <Redirect to={PATHES.PATH_TO_LOGIN_PAGE}/>
    }

};

export default PrivateRoleRoute;