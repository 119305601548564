import {useContext} from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import {AlertContext} from '../../context/AlertContext'

function  AlertExcel() {

  const {isOpen,propsAlert,closeAlert} = useContext(AlertContext)

  return (
     <>
         {isOpen && (
                <Snackbar onClose={closeAlert}  autoHideDuration={propsAlert.autoHideDuration} anchorOrigin={{ vertical: propsAlert.anchor.vertical,  horizontal:propsAlert.anchor.horizontal }} open={true}> 
                <MuiAlert
                  onClose={closeAlert} 
                    elevation={6} 
                    variant="filled"    
                    severity={propsAlert.severity}
                >
                {propsAlert.text}
                </MuiAlert> 
           </Snackbar> 
         )}  
     </>
     
  )
}

export default AlertExcel