import instanceServerApi from "../../AxiosInstanse/serverApi";



export  function checkBitrixContact(email){
    return instanceServerApi.post("/api/services/bitrix/post", {
        "url": "crm.contact.list",
        "body": {
            "filter": { "EMAIL": email},
            "select": ["*", "UF_*" ,"EMAIL"]
        }
    })
}
export async function checkBitrixCompany(codeMDG) {
   
    const res = await instanceServerApi.post( '/api/services/bitrix/companyList', {
        filter: {'UF_CRM_1637305277': codeMDG},
        select: ['*', 'UF_*']
    })

    if(res.data.response.length > 0) return res.data.response[0].ID
    return null
}

export async function createBitrixContact(email="test@test.ru",firstName="",lastName="", secondName='', phoneNumber="",codeMDG=undefined){

    let fields ={
                "NAME": firstName, 
                "SECOND_NAME": secondName, 
                "LAST_NAME": lastName, 
                "OPENED": "Y",
                "ASSIGNED_BY_ID": 1826, 
                "TYPE_ID": "CLIENT",
                "SOURCE_ID": "SELF",
                "EMAIL": [ { "VALUE": email, "VALUE_TYPE": "WORK"} ] ,
                "PHONE": [ { "VALUE": phoneNumber, "VALUE_TYPE": "WORK" } ] 
    }

    const companyId = await checkBitrixCompany(codeMDG)
    if(companyId !== null) fields["COMPANY_ID"] = companyId

    return instanceServerApi.post("/api/services/bitrix/post", {
        "url": "crm.contact.add",
        "body":  {
            fields
        }
    })

   
}

