import React from 'react';
import style from './assets_style.module.scss'


const InputSample = React.forwardRef(({required,label, stateHook,type, width,objKey,disable_label,placeholder,handle,handleprops, value,...inputsProps}, ref) => {
    return (
        <div style={{width: width}} className={style.sample_form_input}>
            {!disable_label && (
                <span className={`${style.label_sample_form_input} ${required? style.required: ""}`}>
                {label}
            </span>
            )}
            <input placeholder={placeholder} className={style.sample_input} type={type}
                   defaultValue={value}
                   {...inputsProps}
                   onChange={(event)=>{
                    if(stateHook)  stateHook(prevState=>({...prevState, [objKey]: event.target.value}))
                    if(handle) handle(handleprops,event)
                }}
            />
        </div>
    );
});
InputSample.defaultProps={
    value: null,
    placeholder: "",
    disable_label: false,
    width: "150px",
    label: "Стандартный заголовок",
    type: "text",
}

export default InputSample;