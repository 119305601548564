import React, {useContext, useEffect} from 'react';
import MuiAlert from "@material-ui/lab/Alert";
import MuiAlertTitle from "@material-ui/lab/AlertTitle"
import {dateIn} from "../../lib/utils/getDate";
import {makeStyles} from '@material-ui/styles'
import {useHistory} from "react-router";
import FlexRow from "../../containers/FlexContainers/FlexRow";
import {UserContext} from "../../context/UserContext";

const useStyles = makeStyles(()=>({
    root :{

        height:"100%",
    },
    message: {
        width: "100%"
    }


}))


function NotificationsCard({item}) {
    const classes = useStyles()
    const history = useHistory()
    const {userInfo} = useContext(UserContext)
     function handleLinkNotifications(link){
        let url
         const matchHTTP = link.match(/^(http|https):\/\//g)

         if(matchHTTP === null) link = new URL("https://" + link)
         else url = new URL(link)

         // if(url.origin === process.env.REACT_APP_HTTP_SERVER) {
         //     history.push(url.pathname)
         // }else {
         //     window.open(link, "_blank")
         // }
         window.open(link, "_blank")

    }

    useEffect(()=>{

        if(item.link && userInfo.role !=="ADMIN") {
           document.getElementById(`${item.id}`).addEventListener('click', ()=>{handleLinkNotifications(item.link)});
        }
    },[])

    return (
        <div id={item.id} style={{width:"100%",height:"100%"}} className={item.link ? "hover_pointer" : ""}>
            <MuiAlert severity={item.priority}  classes={{"message": classes.message, "root": classes.root}} >
                <MuiAlertTitle>{item.title}</MuiAlertTitle>
                <FlexRow flexDirection={"column"} height={"auto"} >
                    <p style={{fontSize: "12px"}}>{item.body}</p>
                    <p style={{marginLeft:"auto"}}>{dateIn(item.dateIn)}</p>
                </FlexRow>
            </MuiAlert>
        </div>
    );
}

export default NotificationsCard;