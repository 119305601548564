import {createContext, useState,useCallback } from 'react';

export const LoadingDataContext = createContext({})

function LoadingDataProvider({children}) {

    const [load, setLoad] = useState(false)

    const [propsAlert, setPropsAlert] = useState({
        anchor: {
            vertical: 'top',
            horizontal:'center'
        },
        autoHideDuration: null,
        severity: "info",
        text: 'Идет загрузка данных'
    })

    const startLoading= useCallback(()=> setLoad(true),[])

    const endLoading = useCallback(()=> setLoad(false),[])


    return (
       <LoadingDataContext.Provider value={{load, startLoading, endLoading,propsAlert, setPropsAlert}}>
           {children}
       </LoadingDataContext.Provider>
    );
}

export default LoadingDataProvider;