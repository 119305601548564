// import axios from 'axios'

// let baseURL = ""

// if(process.env.NODE_ENV === 'production')  baseURL = process.env.REACT_APP_AXIOS_URL
// if(process.env.NODE_ENV === 'development') baseURL = process.env.REACT_APP_AXIOS_URL


// const instanceAutoVizit = axios.create({
//     baseURL: baseURL,
//     timeout: 10000
// });


export function getCookie(name) {
    let value = "; " + document.cookie;
    let parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
}


export function setCookie(name,value,days) {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}


// export function getToken() {
//     const params = new URLSearchParams()
//     params.append('username', 'tester')
//     params.append('password', 'tester')
//     params.append('grant_type', 'password')

//     const config = {
//         headers: {
//             'Content-Type': 'application/x-www-form-urlencoded'
//         }
//     }
//     return axios.post(`${baseURL}/token`,params, config)
// }

// instanceAutoVizit.interceptors.request.use(
//     async config => {
//         const value  = getCookie('autoVizit')
//         config.headers = {
//             'Authorization': `Bearer ${value}`,
//             'Accept': 'application/json',
//             'Content-Type': 'application/json'
//         }
//         return config;
//     },
//     error => {
//         Promise.reject(error)
//     });

// //TODO проверить работу структуры axios с автовизитами
// instanceAutoVizit.interceptors.response.use((response) => {
//     return response
// }, async function (error) {
//     const originalRequest = error.config;
//     console.log(error.code)
//     await getToken().then(response=>{
//         setCookie('autoVizit',response.data.access_token,1)
//     })
//     originalRequest._retry = true;

//     if ((error.response?.status === 401 || error.code === 'ECONNABORTED') && !originalRequest._retry) {

//         await getToken().then(response=>{
//             setCookie('autoVizit',response.data.access_token,1)
//         })
//         originalRequest._retry = true;
//         return instanceAutoVizit(originalRequest);
//     }else{
//         console.log(error)
//         console.log(error.response)
//     }
//     return Promise.reject(error);
// });



// export default instanceAutoVizit