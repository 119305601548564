import {useState} from 'react';
import {useSelector} from "react-redux"
import style from "./popup.module.scss"
import DialogContainer from "./DialogContainer"
import {SetCreateTaskPopup} from "../../redux/StateOfStyleObjects"
import InvisibleTextarea from "../shared/InvisibleTextarea"
import {useFormik} from "formik"
import ButtonWithicon from "../shared/ButtonWithicon"
import instanceServerApi from '../../AxiosInstanse/serverApi';


const CreateTaskPopup = () => {
    const createTaskPopup = useSelector(state => state.styleState.createTaskPopup)
    const [files, setFiles] = useState(null)
    const [filesName, setFilesName] = useState([])
    // const forceUpdate = useForceUpdate()

    function sendMessage(values) {
        instanceServerApi.post('/api/tasks/create', {
            content: values.message,
            title: values.title
        }).then(response => {
            alert(`Ваше обращение отправлено под номером ${response.data.response.id}. Статус и другие обращения вы можете отследить во вкладке Помощь->Список вопросов`)
        }).catch(e => console.log(e))
    }

    const validate = values => {

        const errors = {};
        if ((values.message === "" && files === null)) {
            errors.message = 'Required';
        }
        return errors;
    };
    const formik = useFormik({
        initialValues: {
            message: "",
            title: ''
        },
        validate,
        onSubmit: values => {
            if (values.message !== "" && values.title !== "") {
                sendMessage(values)
            }
            values.message = ""
            values.title = ""
            setFilesName([])
        }
    })

    return (
        <DialogContainer popupOpen={createTaskPopup} SetPopup={SetCreateTaskPopup}>
            <div className={`${style.create_task}`}>
                <p style={{marginBottom: "30px"}}>
                    Задать вопрос в поддержку
                </p>
                <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
                    <div className={style.create_theme_input}>
                        <InvisibleTextarea name={"title"} handleChange={formik.handleChange}
                                           message={formik.values.title} placeholder={"Тема обращения"}/>
                    </div>
                    <div className={style.create_theme_input_message}>
                        <InvisibleTextarea name={"message"} handleChange={formik.handleChange}
                                           message={formik.values.message} placeholder={"Текст обращения"}/>
                    </div>
                    <ButtonWithicon icon={'/icons/ico_add.svg'} type='submit'>Задать вопрос</ButtonWithicon>
                  
                </form>
            </div>
        </DialogContainer>

    );
};

export default CreateTaskPopup;