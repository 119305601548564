import React from 'react';
import style from './assets_style.module.scss'

const InvisibleTextarea = (props) => {
    return (
        <textarea
            placeholder={props.placeholder}
            className={style.naked_input}
            name={props.name}
            id={props.name}
            value={props.message}
            onChange={props.handleChange}
        />
    );
};

export default InvisibleTextarea;