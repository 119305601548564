import React, {useCallback, useContext, useEffect, useState} from 'react'
// import { useSelector } from 'react-redux';
import {useForm} from 'react-hook-form';
import {UserContext} from '../../context/UserContext';
import './static.scss'
import instanceServerApi from '../../AxiosInstanse/serverApi';
import createPDFDocument from '../../lib/create-pdf/pdf-create';
import {ReactSVG} from 'react-svg';
import ErrorsSpan from '../../components/shared/ErrorsSpan';
import {useSedECPStore} from "../../modules/sed_documents/store/sed_ecp_store";
import DialogContent from "@material-ui/core/DialogContent";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

const FlexRow = (props) => {
    return (
        <div className='flex-row'>
            {props.children}
        </div>
    )
}

const InputLabel = (props) => {
    return (
        <label>
            {props.label}
            {props.children}
        </label>
    )
}

function NewContractForm(props) {
    const {userInfo} = useContext(UserContext)
    const {setValue, register, handleSubmit, formState: {errors}} = useForm();
    const setFileBase64 = useSedECPStore(state => state.setFileBase64)
    const setSignatureBase64 = useSedECPStore(state => state.setSignatureBase64)
    const setDogovorSettlement = useSedECPStore(state => state.setDogovorSettlement)
    const setPropertyState = useSedECPStore(state => state.setPropertyState)
    const [checkingAccaunt, setCheckinsAccaunt] = useState([])

    useEffect(()=>{
        instanceServerApi.post('/api/profile/balance',{
            "method": "getsigner",
            "inn": userInfo.company.id,
            "isPayments": 2
        }).then((res)=>{
            if(res.data.response.fio.length > 3) {
                const info = res.data.response.fio.split(" ")
                    setValue('firstName', info[1])
                setPropertyState(info[0], "fio")
                    setValue('lastName', info[0])
                    setValue('secondName', info[2])
                setValue('full_role',  res.data.response.position)
            }
        })
    },[])

    const onSubmit = data => {
        setDogovorSettlement(data.summer)
        createPDFDocument(data, setFileBase64, setSignatureBase64,userInfo.company)
    };

    const setCheckingAccauntInfo = (AccountNumber) => {
        const accaunt = checkingAccaunt.find(item => item.AccountNumber === AccountNumber)
        if (accaunt) {
            setValue('bik', accaunt.BankBIK)
            setValue('bank_name', accaunt.BankName)
            setValue('bank_address', accaunt.BankCity + "  " + accaunt.BankAddress)
            setValue('summer', accaunt.AccountNumber)
            setValue('korr_summer', accaunt.CorrespondentAccounts)
        }
    }


    useEffect(() => {
        instanceServerApi.post('/api/guide/check-company', {
            "Type_request": "1",
            "Partners": [
                {
                    "INN": userInfo.company.inn + '',
                    "KPP": userInfo.company.kpp + ''
                }]
        }).then(res => {
            if (res?.data?.response?.Partners) {
                setValue('legal_address', res.data.response.Partners[0].LegalAddress)
                setValue('company_name', res.data.response.Partners[0].Name)
            }

            if (res?.data?.response?.Partners[0].CheckingAccount) {
                setCheckinsAccaunt(res.data.response.Partners[0].CheckingAccount)
            } else {
                setCheckinsAccaunt(null)
            }

        })


    }, [])

    return (
        <>
            <div className='flex-row-margin0'>
                <h4>
                    Договор на информационные услуги с ЗАО “КТСП”
                </h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className='form' method="dialog">

                <DialogContent dividers>
                    <p className={"title_dividers"}>ФИО подписанта договора, email и его должность</p>
                    <FlexRow>
                        <InputLabel label="Фамилия в именит. падеже">
                            <input type="text" placeholder="Фамилия" {...register("lastName", {required: true})}
                                   onChange={(event) => setPropertyState(event.target.value, "fio")}/>
                        </InputLabel>
                    </FlexRow>
                    <FlexRow>
                        <InputLabel label="Фамилия в род. падеже">
                            <input type="text" placeholder="Фамилия" {...register("lastNameRod", {required: true})}
                            />
                        </InputLabel>
                        <InputLabel label="Имя в род. падеже">
                            <input type="text" placeholder="Имя" {...register("firstNameRod", {required: true})} />
                        </InputLabel>
                        <InputLabel label="Отчество в род. падеже">
                            <input type="text" placeholder="Отчество" {...register("secondNameRod", {required: true})} />
                        </InputLabel>
                    </FlexRow>

                    <FlexRow>
                        <InputLabel label="Адрес email для заявок/уведомлений">
                            <input type="text" placeholder="Email" {...register("email", {required: true})} />
                        </InputLabel>
                        <InputLabel label="Адрес email для направления бухгалтерских документов">
                            <input type="text" placeholder="Email" {...register("email_buh", {required: true})} />
                        </InputLabel>
                    </FlexRow>

                    <FlexRow>

                        <InputLabel label="Должность подписанта договора">
                            <input type="text"
                                   placeholder="Должность подписанта договора" {...register("full_role", {required: true})} />
                        </InputLabel>
                    </FlexRow>
                    <InputLabel label="Должность подписанта договора в родительном падеже">
                        <input type="text" placeholder="" {...register("role", {required: true})} />
                    </InputLabel>
                </DialogContent>
                <DialogContent dividers>
                    <p className={"title_dividers"}>Основание</p>
                    <InputLabel label="Основание для права подписи">
                        <input type="text"
                               placeholder="Основание для права подписи" {...register("osnovanie", {required: true})} />
                    </InputLabel>
                </DialogContent>
                <DialogContent dividers>
                    <p className={"title_dividers"}>Данные по компании</p>
                    <FlexRow>
                        <InputLabel label="Компания">
                            <input type="text"
                                   placeholder='Наименование компании'  {...register("company_name", {required: true})}/>
                        </InputLabel>
                        <InputLabel label="Телефон компании">
                            <input type="text"
                                   placeholder='Телефон компании'  {...register("phone_number", {required: true})}/>
                        </InputLabel>
                    </FlexRow>
                    <FlexRow>
                        <InputLabel label="ИНН">
                            <input type="text"
                                   defaultValue={userInfo.company.inn} {...register("INN", {required: true})}/>
                        </InputLabel>
                        <InputLabel label="КПП">
                            <input type="text"
                                   defaultValue={userInfo.company.kpp}  {...register("KPP", {required: true})}/>
                        </InputLabel>

                    </FlexRow>
                    <FlexRow>
                        <InputLabel label="№ ОГРН">
                            <input type="text" placeholder='ОГРН'  {...register("ogrn", {
                                required: true,
                                minLength: 9
                            })}/>
                            {errors.ogrn && <ErrorsSpan text='Количество знаков должно быть 9'/>}
                        </InputLabel>
                    </FlexRow>
                    <FlexRow>
                        <InputLabel label="Юридический и фактический адрес">
                            <input type="text"
                                   placeholder='Юридический и фактический адрес'  {...register("legal_address", {required: true})}/>
                        </InputLabel>
                    </FlexRow>

                </DialogContent>
                <DialogContent dividers>
                    <p className={"title_dividers"}>Данные банка</p>
                    {checkingAccaunt && (
                        <Form.Group as={Col} lg={8}>
                            <Form.Label>Вы можете выбрать доступные данные по вашей компании</Form.Label>
                            <Form.Select type='text' onChange={e => {
                                setCheckingAccauntInfo(e.target.value)
                            }}>
                                <option value="" style={{display: "none"}}></option>
                                {checkingAccaunt.map(item => (
                                    <option value={item.AccountNumber}>{item.BankName}</option>
                                ))}

                            </Form.Select>
                        </Form.Group>
                    )}
                    {!checkingAccaunt && (
                        <p>Мы не нашли данных банков по вашей компании</p>
                    )}

                    <FlexRow>
                        <InputLabel label=" № БИК">
                            <input type="text" placeholder='БИК'  {...register("bik", {
                                required: true,
                                minLength: 9,
                                maxLength: 9
                            })}/>
                            {errors.bik && <ErrorsSpan text='Количество знаков должно быть 9'/>}
                        </InputLabel>

                    </FlexRow>

                    <FlexRow>
                        <InputLabel label="Наименование банка">
                            <input type="text"
                                   placeholder='Наименование банка'  {...register("bank_name", {required: true})}/>
                        </InputLabel>
                        <InputLabel label="Адрес банка">
                            <input type="text"
                                   placeholder='Адрес банка'  {...register("bank_address", {required: true})}/>
                        </InputLabel>
                    </FlexRow>
                    <FlexRow>
                        <InputLabel label=" № Расчетный счет банка">
                            <input type="text" placeholder='Расчетный счет'  {...register("summer", {
                                required: true,
                                minLength: 20,
                                maxLength: 20
                            })}/>
                            {errors.summer && <ErrorsSpan text='Количество знаков должно быть 20'/>}
                        </InputLabel>
                        <InputLabel label="№ Корр.счет банка">
                            <input type="text" placeholder='Корр.счет'  {...register("korr_summer", {
                                required: true,
                                minLength: 20,
                                maxLength: 20
                            })}/>
                            {errors.korr_summer && <ErrorsSpan text='Количество знаков должно быть 20'/>}
                        </InputLabel>
                    </FlexRow>
                </DialogContent>

                <input type="submit" value="Сформировать"/>


            </form>
        </>


    )
}

export default NewContractForm