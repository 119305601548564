import React, {useContext} from 'react';
import {UserContext} from "../context/UserContext"
import AdminRoute from "../routes/AdminRoute";
import {roles} from "../constants/AuthRole"
import ClientRoute from "../routes/ClientRoute";
import IlsarWorkerRoute from "../routes/IlsarWorkerRoute";
import WagonRoutes from "../routes/WagonRoutes";
import AdminWagonRoutes from "../routes/AdminWagonRoutes";
import AdminKtsp from "../routes/AdminKTSP";
import SupportHelperText from "../components/support_helper/SupportHelperText";

const DashboardContent = () => {
    const {userInfo} = useContext(UserContext)
    return (
        <>
            {userInfo.role === roles.admin && (
                <AdminRoute/>
            )}
            {(userInfo.role === roles.mainClient || userInfo.role === roles.client) && (
                <>
                    <ClientRoute/>
                    <SupportHelperText />
                </>
            )}

            {userInfo.role === roles.ilsar && (
                <IlsarWorkerRoute/>
            )}
            {userInfo.role === roles.wagon && (
                    <WagonRoutes/>
            )}
            {userInfo.role === roles.admin_wagon && (
                <AdminWagonRoutes />
            )}
            {userInfo.role === roles.admin_ktsp_incoming && (
                <AdminKtsp />
            )}

        </>
    );
};

export default DashboardContent;