import React, {useContext, useEffect, useState} from "react";
import {Redirect, Route, BrowserRouter as Switch} from "react-router-dom"
import {ThemeProvider} from '@material-ui/core/styles';
import {PATHES} from "./constants/RoutesPath"
import {theme} from "./styles/MuiTheme/Theme"
import Layout from "./containers/Layout/Layout"
import 'react-loading-skeleton/dist/skeleton.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {UserContext} from "./context/UserContext"
import CookiesSubmit from "./components/CookiesSubmit/CookiesSubmit";
import {getCookie} from "./lib/utils/cookies";
import RedirectComponent from "./routes/RedirectComponent";
import './styles/konversta.scss'
// import 'react-phone-input-2/lib/style.css'

const LoginPage = React.lazy(() => import('./pages/login/LoginPageMain'))
const RegistrationPage = React.lazy(() => import('./pages/login/RegistrationPage'))


function App() {
    const [cookie, setCookie] = useState(false)

    useEffect(() => {

        document.body.style.overscrollBehavior = "auto"




        document.title = "Личный кабинет клиента"
        const checkCokie = getCookie('submit_cookie')
        if (checkCokie !== undefined) setCookie(true)
    }, [])
    const {loginUser} = useContext(UserContext)

    if (loginUser === true) return (
        <ThemeProvider theme={theme}>
            {!cookie && (
                <CookiesSubmit/>
            )}
            <Switch>
                <Layout/>
                <RedirectComponent/>
            </Switch>
        </ThemeProvider>
    )
    if (loginUser === false) return (
        <ThemeProvider theme={theme}>
            {!cookie && (
                <CookiesSubmit/>
            )}
            <React.Suspense fallback={<div>...</div>}>
                <Switch>
                    <Route exact path={PATHES.PATH_TO_LOGIN_PAGE} component={LoginPage}/>
                    <Route exact path={PATHES.PATH_TO_REGISTRATION} component={RegistrationPage}/>
                    <Redirect to={PATHES.PATH_TO_LOGIN_PAGE}/>
                </Switch>
            </React.Suspense>
        </ThemeProvider>
    )
    if (loginUser === null) return (
        <div>...Загрузка</div>
    )
}

export default App;
