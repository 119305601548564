import {useMemo} from "react";
import ccpa from "crypto-pro-cadesplugin";


const useDoCertsList = (inn = null)=> useMemo(async ()=>{
    try{
        const certsApi = await ccpa();
        const certsList = await certsApi.getCertsList();
        console.log(certsList)
        const list = []
        for (const cert of certsList) {
            const obj = cert.friendlySubjectInfo()
            const obj_user = cert.friendlyIssuerInfo()
            let check = 0
            let label = ""
            let fio = ''

            await cert.isValid().then(status=>{
                if(status) {
                    obj.forEach(item=>{
                        if(item.value === "Фамилия") {
                            label = label + ` ${item.text}`
                            fio = item.text
                        }
                        if(item.value === "Имя/Отчество") label = label +` ${item.text}`
                        if(item.value === "Компания") label = label +` ${item.text}`

                        if(item.value === "Должность" ) {
                            const post = item.text.toLowerCase()
                            // if (post.includes('директор')) {
                            //     check++
                            //     label = label + ` ${item.text}`
                            // }
                            label = label + ` ${item.text}`
                        }
                        if(item.value == "ИНН Компании") {
                            if(item.text.length ===10 || item.text.length ===12 ) {
                                if(item.text == inn && inn){
                                    check++
                                    label = label +` ИНН=${item.text}`
                                }
                            }
                        }
                        if(item.value == "ИНН") {
                            if(item.text.length === 12 ) {
                                if(item.text == inn && inn){
                                    check++
                                    label = label +` ИНН ИП=${item.text}`
                                }
                            }
                        }


                        if(item.value === undefined && item.text) {
                            if(item.text.length ===10 || item.text.length ===12 ) {
                                if(item.text == inn && inn){
                                    check++
                                    label = label +` ИНН=${item.text}`
                                }

                            }
                        }
                    })

                    // obj_user.forEach(item=>{
                    //     if(item.value === "Удостоверяющий центр" && item.text === "Федеральная налоговая служба" ) check++
                    //
                    // })

                    if(check === 1 || check === 2) {
                        list.push({
                            value: cert.thumbprint,
                            label: cert.subjectInfo,
                            fio: fio,
                            friendInfo: obj,
                            userInfo: obj_user
                        })
                    }
                }
            })
        }

        // const list = certsList.map((cert) => ({
        //     value: cert.thumbprint,
        //     label: cert.subjectInfo
        // }));
        return Promise.resolve(list);
    } catch (e) {
        console.log(e)
        return Promise.reject(e)
    }
},[])


export default useDoCertsList