import React, {useContext, useState} from 'react';
import Form from "react-bootstrap/Form";
import useDoCertsList from "../../../hooks/certList";
import {UserContext} from "../../../context/UserContext";

function CertSelect(props) {
    const {userInfo} = useContext(UserContext)
    useDoCertsList(userInfo.company.inn).then((list) => setListSert(list)).catch(err => alert("Плагин недоступен. Для подписание ЭЦП установите или проверьте работу CryptoPro WebPlugin"))

    const [listSert, setListSert] = useState([{value: "", label: "подпись"}]);

    return (
        <Form.Group>

            <Form.Label>Выберите сертификат подписи</Form.Label>
            <Form.Select aria-label="thumbprint select" name="thumbprint" onChange={e => {
                props.handle(e.target.value)
            }}>
                <option value="" style={{display: "none"}}></option>
                {
                    listSert.map(item => (
                        <option value={item.value}>{item.label}</option>
                    ))
                }
            </Form.Select>
        </Form.Group>
    );
}

export default React.memo(CertSelect);