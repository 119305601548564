import React from 'react';
import PropTypes from 'prop-types'

const FlexRow = ({children, spacing, alignItems, justifyContent, wrap, flexDirection, spacingRow,height,width, stylesProp, ...flexProps}) => {
    return (
        <div style={{
            height: height,
            width: width,
            display: "flex",
            columnGap: spacing + "px",
            alignItems: alignItems,
            justifyContent: justifyContent,
            flexWrap: wrap ? "wrap" : 'nowrap',
            flexDirection: flexDirection,
            rowGap: spacingRow + "px",
            ...stylesProp
        }}
             {...flexProps}
        >
            {children}
        </div>
    );
};

FlexRow.propTypes = {
    height: PropTypes.string,
    spacing: PropTypes.number,
    alignItems: PropTypes.string,
    justifyContent: PropTypes.string,
    wrap: PropTypes.bool,
    flexDirection: PropTypes.string,
    spacingRow: PropTypes.number
}

FlexRow.defaultProps = {
    height: "100%",
    width: 'auto',
    spacing: 20,
    alignItems: "start",
    justifyContent: "space-between",
    flexDirection: 'row',
    wrap: true,
    spacingRow: 20
}

export default FlexRow;