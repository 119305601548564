import React, {useContext} from 'react';
import {UserContext} from '../../context/UserContext'
import {ReactSVG} from "react-svg";
import style from './style.module.scss'
function SupportHelperText() {
    const {userInfo} = useContext(UserContext)
    function mailToLink() {
        const link = document.createElement('a')
        link.href = `mailto:support.lkk@port.one?subject=Запрос на поддержку от  компании ${userInfo.company.name}. ИНН: ${userInfo.company.inn}`;
        link.click()
        link.remove()
    }
    return (
        <div className={style.button} onClick={(event)=>{
            mailToLink()
        }}>
            <ReactSVG src={'/icons/ico_question.svg'} />
            <p>ОБРАЩЕНИЕ В ПОДДЕРЖКУ</p>
        </div>
    );
}

export default SupportHelperText;