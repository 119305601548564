import {SetSettingsPopup} from "../../redux/StateOfStyleObjects"
import DialogContainer from "./DialogContainer"
import {useDispatch, useSelector} from "react-redux"
import {setAutomobileDirectory,setGeneralInfoFromILSAR} from "../../redux/asyncActions"

const PopupSettings = () => {
    const settingsPopup = useSelector(state => state.styleState.settingsPopup)
    const  dispatch = useDispatch()

    return (
        <DialogContainer popupOpen={settingsPopup} SetPopup={SetSettingsPopup}>
            <div style={{width: "300px"}}>
                Настройки
                <div onClick={()=>{
                    dispatch(setAutomobileDirectory())
                    dispatch(setGeneralInfoFromILSAR())
                }}>
                    Обновить данные ИЛСАР
                </div>
            </div>
        </DialogContainer>
    );
};

export default PopupSettings;