import {create} from 'zustand'
import {immer} from "zustand/middleware/immer";




export  const useUpdateIncomingLetterLKKStore = create(immer((set,get)=>({
    data:{
        companyId: null,
        sedId:"",
        title:"",
        summary:"",
        regNumber:"",
        files: [],
        newFiles:[],
        attachments:[]

    },

    setFile: (file)=>set(state=>{
        state.data.files.push(file)
    }),

    setNewFilesArray : (file)=>set(state=>{
        state.data.files.push(file)
    }),

    setFilesMap: (arrayFile)=>set(state=>{
        state.data.files = arrayFile
    }),


    setParam: (data,param) => set(state=>{
        state.data[param] = data
    }),

    setPreview: (data)=> set(state=>{
        state.data = Object.assign(state.data,data)
    }),
    setDefault: () => set({
        data:{
            companyId: null,
            sedId:"",
            title:"",
            summary:"",
            files: [],
            newFiles:[],
        }
    })

})))