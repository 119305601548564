export const PATHES = {
    PATH_TO_HELPERS: "/helpers",
    PATH_TO_START_PAGE: '/',
    PATH_TO_CLIENT_PAGE: '/dashboard/contracts/list',
    PATH_TO_MANAGER_PAGE: '/manager',
    PATH_TO_LOGIN_PAGE: '/login',
    PATH_TO_USER_PAGE: '/user',
    PATH_TO_REGISTRATION: '/registration',
    PATH_TO_DASHBOARD: '/dashboard',
    PATH_TO_ILSAR_WORKER: '/dashboard/ilsar/race',
    PATH_TO_WAGONS_WORKER: '/dashboard/create_wagons',
    PATH_TO_WAGONS_ADMIN_PAGE: '/dashboard/wagons_page',
    PATH_TO_ADMIN_KTSP_INCOMING:'/dashboard/sed/create_incoming_sed'
}
