

export const SET_AUTO_PACK_TYPE = "SET_AUTO_PACK_TYPE"
export const SET_CARGO_DANGER = "SET_CARGO_DANGER"
export const SET_CARGO_TRANSPORT_DIRECTION = "SET_CARGO_TRANSPORT_DIRECTION"
export const SET_CARGO = "SET_CARGO"
export const SET_LOAD_AUTO_DIRECTORY = "SET_LOAD_AUTO_DIRECTORY"
export const SET_CONTAINER_PASSPORT = "SET_CONTAINER_PASSPORT"
export const SET_AUTOMOBILE_TYPE = "SET_AUTOMOBILE_TYPE"

export const defaultState = {
    load: false,
    AutomobilePackType: [],
    CargoDanger:[],
    CargoTransportDirection: [],
    Cargo:[],
    ContainerPassport:[],
    AutomobileType: []
}


const AutoMobileInstanseInfo = (
    state = defaultState,
    action
)=>{
    switch (action.type) {
        case "SET_AUTO_PACK_TYPE":{
            return {
                ...state,
                AutomobilePackType: action.payload
            }
        }
        case "SET_CARGO_DANGER":{
            return {
                ...state,
                CargoDanger: action.payload
            }
        }
        case "SET_CARGO_TRANSPORT_DIRECTION":{
            return {
                ...state,
                CargoTransportDirection: action.payload
            }
        }
        case "SET_CARGO":{
            return {
                ...state,
                Cargo: action.payload
            }
        }
        case  "SET_LOAD_AUTO_DIRECTORY":{
            return {
                ...state,
                load: true
            }
        }
        case "SET_CONTAINER_PASSPORT":{
            return {
                ...state,
                ContainerPassport: action.payload
            }
        }
        case "SET_AUTOMOBILE_TYPE":{
            return{
                ...state,
                AutomobileType: action.payload
            }
        }
        default:{
            return state
        }

    }
}


export const SetAutomobilePackType = (items) => {
    return {type: SET_AUTO_PACK_TYPE, payload: items}
}

export const SetCargoDanger = (items) => {
    return {type: SET_CARGO_DANGER, payload: items}
}

export const SetCargoTransportDirection = (items) => {
    return {type: SET_CARGO_TRANSPORT_DIRECTION, payload: items}
}
export const SetCargo = (items) => {
    return {type: SET_CARGO, payload: items}
}

export const selLoading = () =>{
    return{type: SET_LOAD_AUTO_DIRECTORY}
}

export const SetContainerPassport = (items) =>{
    return {type: SET_CONTAINER_PASSPORT, payload: items}
}

export const SetAutomobileType = (items) =>{
    return {type: SET_AUTOMOBILE_TYPE, payload: items}
}


export default AutoMobileInstanseInfo;