import Dialog from '@material-ui/core/Dialog';
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    

    paper :{
        margin:"0px",
        position: "absolute",
        right: "0",
        height: "100%"
    }
}));


const CustomDialog = (props) => {
    const {children,open,SetPopup, ...other} = props
   
    const handleClose = () => {
        SetPopup(false)
    };

    const classes = useStyles()

    return (
        <Dialog onClose={handleClose}  open={open} classes={{paper: classes.paper}}>
            {children}
        </Dialog>
    );
};

export default CustomDialog;