import React, {useState} from 'react';
import MuiAlert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";

function AlertCompanyError(props) {
    const [open,setOpen] = useState(true)
    return (
        <Collapse  collapsedSize={"auto"} in={open} unmountOnExit>
            <MuiAlert variant="filled"
                      onClose={()=>{setOpen(false)}}
                      severity={"error"}>
                <div>
                    <h4>Уважаемый клиент!</h4>
                    <ul>
                        <li>Мы обнаружили ошибку в данных по вашей компании</li>
                        <li>Если вы видите это уведомление, пожалуйста, не формируйте договор для отправки на подпись</li>
                        <li>Пожалуйста, напишите письмо в техническую поддержку ЛКК, для сверки корректности данных у нас в системе</li>
                        <li>Возможно произошла внутренная ошибка.Мы как можно скорее её исправим</li>
                    </ul>
                </div>
            </MuiAlert>
        </Collapse>
    );
}

export default AlertCompanyError;