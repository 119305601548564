import IconButton from "@material-ui/core/IconButton"
import {ReactSVG} from "react-svg"
import PropTypes from 'prop-types'

 const UploadFileIcon = ({setFile, ...props}) => {
    return (
        <>
            <input style={{display: "none"}} name="files" id="files" type="file" {...props} onChange={(e) => {
                setFile(e.target.files[0])
            }}/>
            <label htmlFor="files" >
                <IconButton id="files" color="primary" aria-label="upload files" component="span">
                    <ReactSVG src='/icons/ico_upload.svg'/>
                </IconButton>
            </label>
        </>
    )
}

UploadFileIcon.propsType ={
    setFile: PropTypes.func.isRequired
}


export default UploadFileIcon

