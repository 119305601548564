import React, {Suspense} from 'react';
import {
    Route,
    Switch
} from 'react-router-dom';
import PrivateRoleRoute  from "../components/HOC/PrivateRoleRoute"
import NotFound from "../components/NotFound/NotFound";
import IncomingLKKAdmin from "../pages/sed_post/IncomingLKKAdmin";
import UpdateLetterForm from "../modules/sed_incoming_letter_lkk/components/UpdateLetterForm";

const CreateLetterLKKForm = React.lazy(()=>import('../pages/create_letter_in_lkk'))



function AdminKtsp() {
    return (
        <Suspense fallback={<div>...Загрузка</div>}>
            <Switch>
                <PrivateRoleRoute exact path='/dashboard/sed/create_incoming_sed' component={CreateLetterLKKForm}  />
                <PrivateRoleRoute path='/dashboard/requests/official/admin_incoming_messages' component={IncomingLKKAdmin}/>
                <PrivateRoleRoute path='/dashboard/requests/official/update_incoming_sed/:id' component={UpdateLetterForm}/>
                <Route path='*' component={NotFound}/>
            </Switch>
        </Suspense>
    );
}

export default AdminKtsp;