export const SET_PARTNER = "SET_PARTNER"
export const SET_DRIVERS = "SET_DRIVERS"
export const SET_LOAD_GENERAL_INFO = "SET_LOAD_GENERAL_INFO"
export const SET_AUTOMOBILE_TRANSPORT = "SET_AUTOMOBILE_TRANSPORT"
export const SET_FORWARD_AGENT = "SET_FORWARD_AGENT"

export const defaultState = {
    load: false,
    Partner: [],
    Drivers: [],
    AutomobilePassport:[],
    Agents:[]
}


const GeneralInfoFromILSAR = (
    state = defaultState,
    action
)=>{
    switch (action.type) {
        case "SET_PARTNER":{
            return {
                ...state,
                Partner: action.payload
            }
        }
        case "SET_DRIVERS":{
            return {
                ...state,
                Drivers: action.payload
            }
        }
        case  "SET_LOAD_GENERAL_INFO":{
            return {
                ...state,
                load: true
            }
        }
        case 'SET_FORWARD_AGENT':{
            return {
                ...state,
                Agents: action.payload
            }
        }
        case "SET_AUTOMOBILE_TRANSPORT":{
            return {
                ...state,
                AutomobilePassport: action.payload
            }
        }
        default:{
            return state
        }

    }
}

export const SetForwardAgents = (items)=>{
    return {type: SET_FORWARD_AGENT,payload: items}
}
export const SetPartner = (items) => {
    return {type: SET_PARTNER, payload: items}
}

export const SetDrivers = (items) => {
    return {type: SET_DRIVERS, payload: items}
}

export const SetLoadingGeneralInfo = () =>{
    return{type: SET_LOAD_GENERAL_INFO}
}

export const SetAutomobileTransport = (items)=>{
    return {type: SET_AUTOMOBILE_TRANSPORT, payload: items}
}

export default GeneralInfoFromILSAR;