import { ReactSVG } from "react-svg"

export function setStatus(status) {
    switch (status) {
        case "Зарегистрирован":
        case "Подписан":
        case "Рассмотрен":
            return (
                <ReactSVG title={status}  src={'/icons/tbl_status_ok.svg'}/>
            )
        case "Согласован":
        case "Регистрация":
        case "Подписание":
            return (
                <ReactSVG title={status} src={'/icons/tbl_status_in_progress.svg'}/>
            )
        case "Согласование":
        case "Консолидация":
            return (
                <ReactSVG title={status} src={'/icons/tbl_status_timeout.svg'}/>
            )
        case "Подготовка проекта":
        case "Отложенный номер":
            return (
                <ReactSVG title={status} src={'/icons/tbl_status_draft.svg'}/>
            )
        case "Отменен":
        case "Аннулирован":
            return (
                <ReactSVG title={status} src={'/icons/tbl_status_canceled.svg'}/>
            )
        default:
            return (
                <span>Без статуса</span>
            )

    }
}