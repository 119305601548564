import Dialog from '@material-ui/core/Dialog';
import {useDispatch} from "react-redux"
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    
    paper :{
        margin:"0px",
        position: "absolute",
        right: "0",
        height: "100%"
    }
}));


const DialogContainer = (props) => {
    const {children,popupOpen,SetPopup, ...other} = props
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(SetPopup(false))
    };

    const classes = useStyles()

    return (
        <Dialog onClose={handleClose}  open={popupOpen} classes={{paper: classes.paper}}>
            {children}
        </Dialog>
    );
};

export default DialogContainer;