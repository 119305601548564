import {useEffect, useState} from 'react';
import style from './sedstyle.module.scss'
import {ReactSVG} from "react-svg"
// import {Document, Page, pdfjs} from "react-pdf"
// import {setStatus} from "./SedInfoBlock"
import { setStatus } from '../../lib/utils/sed/setStatus';
import {openSedFile} from '../../lib/services/openSedFile'
import CustomDialog from '../../components/Popup/CustomDialog';
import {getStatusPost} from "../../modules/sed_posts/helpers/getStatusIcon";

//TODO убрали работу с файлами pdf 
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`


const PopupLetter = (props) => {

    const [docs, setDocs] = useState([])
    
    useEffect(() => {
        if (props.letter?.relatedAttachmentsLinks !== undefined) {
            const documents = props.letter?.relatedAttachmentsLinks.split(';').filter(item=> {
                const type = item.split('.').pop()
                if(type.toLowerCase() !== 'doc' && type!=='docx') return item
            })
            setDocs(documents)
        }
    }, [props.letter])

    return (
        <CustomDialog SetPopup={props.setOpen} open={props.open}>
            <div className={style.popup_dialog}>
                <div className={style.popup_dialog_main}>
                    <div className={style.popup_dialog_mainInfo}>
                        <div className={style.card_corner}>
                            <div className={style.card_corner_rotate}/>
                            <div className={style.card_corner_square}/>
                        </div>
                        <div className={style.popup_dialog_mainInfo_title}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <ReactSVG src={'/icons/ico_plan.svg'}/>
                                <span
                                    className={style.blue_color}>{props.letter?.regNumber ? props.letter?.regNumber : " ДХ.0478"}</span>
                            </div>
                            <ReactSVG src={'/icons/ico_cross.svg'} onClick={() => {
                               props.setOpen(false)
                            }}/>
                        </div>
                        <p style={{display: "flex", justifyContent: "space-between"}}>
                            Статус письма <div style={{display: "flex"}}>
                            {getStatusPost(props.letter?.status)} {props.letter?.status}
                        </div>
                        </p>
                        <p>
                            {props.letter?.executionStatus}
                        </p>
                        <div className={style.popup_dialog_mainInfo_contractSubject}>
                            {props.letter?.summary}
                        </div>
                        <div className={style.popup_dialog_mainInfo_date}>
                            {new Date(props.letter?.regDate).toLocaleDateString()}  &#10230;  {props.letter?.outcomingDate !== undefined ? new Date(props.letter.outcomingDate).toLocaleDateString() : "По настоящее время"}
                            <span>
                           {props.letter?.relatedAttachmentsLinks.split(';').length}
                        </span>
                        </div>
                    </div>
                    <div style={{padding: "20px"}}>
                        Прикрепленные документы:
                        <div>
                            {docs.map(item => (
                                <div className={style.documents_ddd__item} onClick={() => {
                                    openSedFile(item)
                                }}>
                                    {item.replace(/%20/g, ' ').split('/').pop()}
                                </div>

                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </CustomDialog>
    );
};

export default PopupLetter;