import instanceServerApi from "../../AxiosInstanse/serverApi";


// eslint-disable-next-line import/no-anonymous-default-export
export default async function (company) {

    let profile = null
    let id = null
    let companyData = null
    if (company.kpp === null )  company.kpp = ''
    const resCNSI = await instanceServerApi.post('/api/guide/check-company', {
        "Type_request": "1",
        "Partners": [
            {
                "INN": company.inn+"",
                "KPP": company.kpp+""
            },
        ]
    }).catch(err=>{
        console.log(err)
    })

    if(resCNSI.data.response){
        if(resCNSI.data.response?.Partners?.length > 0) {
            id =  resCNSI.data.response.Partners[0].IDMDG
            companyData = resCNSI.data.response.Partners[0]
            companyData.CheckingAccount = undefined
        }
    }


    if(id) {
        const res = await instanceServerApi.get('/api/guide/companies',{
            params:{
                filter : {"id":id}
            }
        })
        if(res.data.response?.length > 0) {
            await instanceServerApi.post('/api/profile/update-company',{
                "companyId":res.data.response[0].id
            })
        }

        if(res.data.response?.length <= 0){
            await instanceServerApi.put('/api/admin/companies/update-company', companyData).then(async (res)=>{
                if(res.data.response?.error===null) {
                    await instanceServerApi.post('/api/profile/update-company',{
                        "companyId":res.data.response.output
                    }).catch(err=>{
                        console.log(err)
                    })
                }
            }).catch(err=>{
                console.log()
            })
        }
        profile = await instanceServerApi.get('/api/profile')
    }else{
        profile = await instanceServerApi.get('/api/profile')
    }
    return profile

}








