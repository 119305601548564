
export function getBase64(file,callback) {
    const reader = new FileReader();
    let base64=""
    reader.readAsDataURL(file);
    reader.onload = function () {
      base64 = getBase64Stroke(reader.result)
      callback(base64)
    };
    reader.onerror = function (error) {
      base64 = error
    };
 }

 function getBase64Stroke(base64) {
   return base64.split(',')[1]
 }

 export function getBase64WithType(file,callback) {
     const reader = new FileReader();
     let base64=""
     reader.readAsArrayBuffer(file);
     reader.onload = function (evt) {

         callback(reader.result)
     };
     reader.onerror = function (error) {
         base64 = error
     };
 }