import React, {useCallback, useContext, useEffect, useState} from 'react';
import style from "./style.module.scss"
import instanceServerApi from "../../AxiosInstanse/serverApi"
// import {debounce} from "@material-ui/core"
// import {SocietySED} from "../../constants/SocietySED"
// import {handleCreateExcel} from '../../modules/sed_posts/helpers/excel'
// import Pagination from "@material-ui/lab/Pagination";
import {useHistory} from "react-router-dom";
import PopupLetter from "../../Dashboard/SED/LetterPopup";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from 'react-bootstrap/Row'
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from '@material-ui/core/CircularProgress';
import {UserContext} from "../../context/UserContext";
import ListGroup from 'react-bootstrap/ListGroup';
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {ReactSVG} from "react-svg";


function DataURIToBlob(dataURI) {
    const byteString = atob(dataURI)
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia])
}


function downloadFileIncoming(id) {
    instanceServerApi.get('/api/services/sed/incoming/file/' + id).then(res => {
        const blob = DataURIToBlob(res.data.response[0].data)
        let blobURL = window.URL.createObjectURL(blob); // Затем создайте доступный URL
        let tempLink = document.createElement('a'); // Создать тег для загрузки
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', res.data.response[0].name);
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
    }).catch(err => {

    })
}


const IncomingLKKAdmin = () => {
    const history = useHistory()
    const [letterInfo, setLetterInfo] = useState(null)
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [alert, setAlert] = useState(false)
    const [letters, setLetters] = useState([])
    const [params, setParams] = useState({sort: {"date": "asc"},filter:{"society": "КТСП"}})
    // const [alert,setAlert] = useState(false)


    const locationToUpdate = (id)=>{
        const location = {
            pathname: `/dashboard/requests/official/update_incoming_sed/${id}`,
            state: { id: id }
        }
        history.push(location)
    }

    useEffect(() => {
        setLoading(true)
        instanceServerApi.get(`/api/services/sed/messages`, {
            params: params
        }).then(res => {
            if (Array.isArray(res.data.response)) {
                setLetters(res.data.response)

            }
            setLoading(false)
        })
    }, [params])


//TODO решить вопрос дублированием элементов в разных блоках
    return (
        <>
            {open && (
                <PopupLetter open={open} setOpen={setOpen} letter={letterInfo}/>
            )}
            {alert && (
                <Snackbar
                    anchorOrigin={{vertical: "center", horizontal: "center"}}
                    open={alert}>
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        severity={'warning'}
                    >
                        Идет формирование отчета по письмам в xlsx
                    </MuiAlert>
                </Snackbar>
            )}


            <Row className={'mb-3'}>
                <Form.Group as={Col} lg={4} md={5}>
                    <Form.Label>Тема письма</Form.Label>
                    <Form.Control
                        onChange={(event) => {
                        }}>
                    </Form.Control>
                </Form.Group>
            </Row>
            <div className={style.post_content}>
                {loading && (
                    <div className={style.loading_circ}>
                        <CircularProgress size={100}/>
                    </div>
                )}
                {!loading && (
                    <Row className="g-3">
                        {letters.map(letter => (
                            <Col lg={4} md={6} xl={4} xxl={4}>
                                <Card style={{width: '100%', height: "100%"}}>
                                    <Card.Body style={{padding: "0px", paddingBottom: "5px"}}>
                                        <Card.Header
                                            style={{fontWeight: "500"}}>
                                            <div>
                                                <p>{letter.title}</p>
                                                <p style={{display:"flex", justifyContent:"space-between",margin:"0px"}}> Дата:{new Date(letter.date).toLocaleDateString()}
                                                    <div style={{display:"flex"}} className={'column-gap-2'}>
                                                        <ReactSVG className={'hover_pointer'} src={'/icons/tbl_status_draft.svg'} onClick={()=>locationToUpdate(letter.id)} />
                                                        {letter.isDeleted ? <ReactSVG src={'/icons/tbl_status_canceled.svg'}/> :  <ReactSVG src={'/icons/tbl_status_ok.svg'}/>}
                                                    </div>
                                                </p>
                                            </div>
                                            </Card.Header>
                                        {/*<Card.Title></Card.Title>*/}
                                        <Card.Text style={{padding: "5px"}}>{letter.summary}</Card.Text>

                                        {letter.attachments && (
                                            <>
                                                <Card.Title style={{padding: "5px"}}>Прикрепленные файлы</Card.Title>
                                                <ListGroup variant="flush">
                                                    {letter.attachments.map(file => (
                                                        <ListGroup.Item onClick={() => {
                                                            downloadFileIncoming(file.id)
                                                        }}>
                                                            <div className={style.documents_ddd__item}>
                                                                {file.name}
                                                            </div>
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>
                                            </>
                                        )}

                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                )}
            </div>
            {/*<Pagination style={{paddingBottom: "30px",paddingTop:"15px"}} page={page} count={count}   onChange={debounce(handleChangePage, 500)}/>*/}
            {/*<p className={style.length_counts}>Количество писем:{length}</p>*/}
        </>
    );
};

export default IncomingLKKAdmin;