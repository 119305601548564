import instanceServerApi from "../../AxiosInstanse/serverApi";

export const uploadFileAvatar = (file, setFileURL) =>{
    const formData = new FormData();
    formData.append('file',file)

    instanceServerApi.post( "/api/profile/avatar", formData,{
            headers: {
                'content-type': "multipart/form-data"
            }
        })
        .then((res) => {
           setFileURL(process.env.REACT_APP_HTTP_SERVER +"/"+res.data.response.avatar)
        })
        .catch((err) => console.log(err));
}

export const uploadFileForUser = (file,id) =>{
    if(!id) return
    const formData = new FormData();
    formData.append('file',file)
    formData.append('userId',id)

    instanceServerApi.post( "/file-server/user-file", formData,{
            headers: {
                'content-type': "multipart/form-data"
            }
        })
        .then((res) => {
           console.log(res.data.response)
        })
        .catch((err) => console.log(err));
}

export const uploadFileForAutoVizitInstanse = (oid, fileBase64,fileName) =>{
    return instanceServerApi.post( "/api/services/ilsar/post",{
        url:"odata/AutomobileInstanceFileData",
        body:{
            AutomobileInstanceOid: oid,
            FileName: fileName,
            Content: fileBase64
        }
    })
}