import UserInfoPopup from "./UserInfoPopup"
import CreateTaskPopup from "./CreateTaskPopup"
import PopupSettings from "./PopupSettings"
import {useSelector} from "react-redux"

const PopupContainer = () => {
        return (
        <>
            <UserInfoPopup/>
            <CreateTaskPopup/>
            <PopupSettings/>
        </>
    );
};

export default PopupContainer;