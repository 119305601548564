import React from 'react';
import style from './assets_style.module.scss'

const DisabledInputSample = ({type, value,label, width}) => {
    // const {type, value,label, width} = props
    return (
        <div className={style.sample_form_input}>
            <span className={style.label_sample_form_input}>
                {label}
            </span>
            <div className={style.sample_input} style={{width: width}}>
                {value}
            </div>
        </div>
    );
};

export default DisabledInputSample;

DisabledInputSample.defaultProps = {
    width: "100px",
    type: "text",
    value: "",
    label: "Стандартный лэйбл"
}