export const clientMenu = {
    list:

        [
            {
                id: 6,
                type: "fill",
                icon: "/icons/ico_sheet_tiles.svg",
                title: "Главная",
                to: "/dashboard"
            },
            // {
            //     id: 7,
            //     type: "fill",
            //     icon: "/icons/ico_request.svg",
            //     title: "Электронная очередь в КТСП",
            //     to: "/dashboard/create-applications-docs",
            //     items: [
            //         {
            //             id: 1,
            //             name: "Создать заявку",
            //             to: '/dashboard/create-applications-docs'
            //         },
            //         {
            //             id: 2,
            //             name: "Просмотр заявок",
            //             to: '/dashboard/view-applications-docs'
            //         }
            //     ]
            // },
            {
                id: 1,
                type: "stroke",
                icon: "/icons/ico_pen.svg",
                title: "Письма и запросы",
                to: "/dashboard/requests/official",
                items: [
                    {
                        id: 1,
                        name: "Письма",
                        to: "/dashboard/requests/official"
                    },
                    {
                        id: 3,
                        name: "Создать письмо",
                        to: "/dashboard/requests/create-official"
                    },
                    {
                        id: 2,
                        name: "Запросы",
                        to: "/dashboard/requests/unofficial"
                    }
                ]
            },

            {
                id: 2,
                type: "stroke",
                icon: "/icons/ico_doc.svg",
                title: "Договоры",
                to: "/dashboard/contracts/list",
                items: [
                    {
                        id: 1,
                        name: "Договоры",
                        to: "/dashboard/contracts/list"
                    },
                    {
                        id: 2,
                        name: "Баланс счета",
                        to: "/dashboard/balance"
                    }
                ]
            },
            {
                id: 5,
                type: "fill",
                icon: "/icons/ico_car.svg",
                title: "Автовизиты",
                to: "/dashboard/autovizit/create-request",
                items: [
                    {
                        id: 1,
                        name: "Автовизиты в МПСП",
                        to: "/dashboard/autovizit/create-request"
                    },
                    {
                        id: 2,
                        type: "solvo",
                        name: "Автовизиты в КТСП",
                        notFound: "/dashboard/solvo-not-found",
                        solvoId: "solvo-truck-appointment-ctsp",
                        to: `${process.env.REACT_APP_SOLVO_URL}/webctspv7/tvr-dashboard`
                    },
                    {
                        id: 3,
                        type: "solvo",
                        name: "Аккредитации",
                        solvoId: `solvo-truck-accreditation`,
                        notFound: "/dashboard/solvo-not-found",
                        to: `${process.env.REACT_APP_SOLVO_URL}/webctspv7/tvr-accreditation-dashboard`
                    }
                ]
            },

            {
                id: 7,
                type: "stroke",
                icon: "/icons/ico_pass.svg",
                title: "Пропуска",
                to: "/dashboard/konversta",
                items: [
                    {
                        id: 1,
                        name: "Пропуска КТСП",
                        to: "/dashboard/konversta-ktsp/new"
                    },
                    {
                        id: 2,
                        name: "Пропуска МПСП",
                        to: "/dashboard/konversta-mpsp/new"
                    },
                    {
                        id: 3,
                        type: "new_window",
                        name: "Пропуска ТМТП",
                        to: "https://yu.konversta.com/ru/tmtp/vue/login"
                    }
                ]
            },
            {
                id: 3,
                type: "stroke",
                to: "/dashboard/cargo",
                icon: "/icons/ico_box.svg",
                title: "Грузы",
                items: [
                    {
                        id: 1,
                        name: "Грузы в МПСП",
                        to: "/dashboard/cargo"
                    },
                    {
                        id: 3,
                        name: "Грузы в УПК",
                        to: "/dashboard/ilsar_upk"
                    },
                    {
                        id: 2,
                        type: "solvo",
                        name: "Грузы в КТСП",
                        notFound: "/dashboard/solvo-not-found",
                        solvoId: "solvo-cargo-ctsp",
                        to: `${process.env.REACT_APP_SOLVO_URL}/webctspv7/containers-dashboard`
                    },
                ]
            }, {
                id: 3,
                type: "stroke",
                to: "/dashboard/wagons_client",
                icon: "/icons/ico_wagon1.svg",
                title: "Дефекты",
                items: [
                    {
                        id: 1,
                        name: "Вагоны в МПСП",
                        to: "/dashboard/wagons_client"
                    }]
        },
            {
                id: 4,
                type: "stroke",
                to: "/dashboard/cargo",
                icon: "/icons/ico_box.svg",
                title: "Грузовые операции",
                items: [
                    {
                        id: 1,
                        type: "solvo",
                        name: "Букинги",
                        notFound: "/dashboard/solvo-not-found",
                        solvoId: "solvo-cargo-ops-booking",
                        to: `${process.env.REACT_APP_SOLVO_URL}/webctspv7/booking-dashboard`
                    },
                    {
                        id: 2,
                        type: "solvo",
                        name: "Операции с контейнерами",
                        notFound: "/dashboard/solvo-not-found",
                        solvoId: "solvo-cargo-ops-container-req",
                        to: `${process.env.REACT_APP_SOLVO_URL}/webctspv7/container-operations-dashboard`
                    },
                    {
                        id: 3,
                        type: "solvo",
                        name: "Оформление контейнеров",
                        notFound: "/dashboard/solvo-not-found",
                        solvoId: "solvo-cargo-ops-container-task",
                        to: `${process.env.REACT_APP_SOLVO_URL}/webctspv7/container-registration-dashboard`
                    },
                    {
                        id: 4,
                        type: "solvo",
                        name: "Оформление доверенностей",
                        notFound: "/dashboard/solvo-not-found",
                        solvoId: "solvo-cargo-ops-poa",
                        to: `${process.env.REACT_APP_SOLVO_URL}/webctspv7/poa-dashboard`
                    },
                    {
                        id: 5,
                        type: "solvo",
                        name: "Судозаходы",
                        notFound: "/dashboard/solvo-not-found",
                        solvoId: "solvo-cargo-ops-vessel-call",
                        to: `${process.env.REACT_APP_SOLVO_URL}/webctspv7/vessel-call/list`
                    }
                ]
            }]
}


export const adminMenu = {
    list: [
        {
            id: 1,
            type: "stroke",
            icon: "/icons/ico_user.svg",
            title: "Список клиентов",
            to: "/dashboard",

        },
        // {
        //     id: 7,
        //     type: "fill",
        //     icon: "/icons/ico_request.svg",
        //     title: "Электронная очередь в КТСП",
        //     to: "/dashboard/admin-applications"
        // },
        {
            id: 3,
            type: "stroke",
            icon: "/icons/ico_user.svg",
            name: "Оформление доверенностей",
            title: "Администрирование",
            items: [
                {
                    id: 1,
                    type: "solvo",
                    name: "Администрирование КТСП",
                    notFound: "/dashboard/solvo-not-found",
                    solvoId: "admin",
                    to: `${process.env.REACT_APP_SOLVO_URL}/webctspv7/admin`
                },
                {
                    id: 2,
                    name: "Настройка уведомлений для компаний",
                    to: "/dashboard/setting_notifications_balance"
                }
            ]
        },
        {
            id: 3,
            type: "stroke",
            icon: "/icons/ico_request.svg",
            title: "Логирование действий",
            to: "/dashboard/logs",
            items: [
                {
                    id: 1,
                    name: "Действия пользователей",
                    to: "/dashboard/users_logs"
                },
                {
                    id: 2,
                    name: "Уведомления по балансу",
                    to: "/dashboard/balance_logs"
                }
            ]
        },
        {
            id: 3,
            type: "stroke",
            icon: "/icons/ico_lightning.svg",
            title: "Настройка стартовой",
            to: "/dashboard/notifications_create",
            items: [ 
                {
                    id: 1,
                    name: "Уведомления",
                    to: "/dashboard/alerts_create"
                },
                {
                    id: 2,
                    name: "Cоздать новость",
                    to: "/dashboard/news_create"
                },
                {
                    id: 4,
                    name: "Новости",
                    to: "/dashboard/news"
                },
                {
                    id: 3,
                    name: "Контакты",
                    to: "/dashboard/contact_create"
                }
            ]

        }

    ]

}

export const adminMenuKAM = {
    list: [
        {
            id: 1,
            type: "stroke",
            icon: "/icons/ico_user.svg",
            title: "Список клиентов",
            to: "/dashboard",
        }
    ]
}

export const adminKTSPIncoming = {
    list: [
        {
            id: 1,
            type: "fill",
            icon: "/icons/ico_pen.svg",
            title: "Создать исходящее",
            to: "/dashboard/sed/create_incoming_sed",
        },
        {
            id: 2,
            type: "fill",
            icon: "/icons/ico_doc.svg",
            title: "Список исходящих",
            to: "/dashboard/requests/official/admin_incoming_messages",
        }
    ]
}