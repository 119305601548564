// import {createMuiTheme} from "@material-ui/core"
import {createTheme} from "@material-ui/core";
import {fontsfamily} from "../../constants/Fonts"
import { ruRU} from '@material-ui/core/locale';


export const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeightRegular: '500'
    },
    overrides: {
        MuiTypography : {
            colorTextSecondary:{
                fontSize: "14px",
                color: "black"
            }
        },
        MuiCssBaseline: {
            '@global': {
                '@font-face': fontsfamily,
            },
        },
        MuiList:{
            padding:{
                paddingTop: "0px",
                paddingBottom: "0px",
            }
        },
        MuiListItem:{
            gutters:{
                height: "62px",
                paddingLeft: "0px",
                paddingRight: "0px"
            },
            root:{
                paddingTop: "4px",
                paddingBottom: "4px"


            }
        },
        
        MuiListItemText:{
            root:{
                margin: "0px",
                wordWrap: "break-word"
               
            }
        },
        MuiTableRow:{
            root:{

            }
        },
        MuiTableHead:{
          root:{
              color: 'rgba(0,0,0,0.5)',
              backgroundColor: "white"
          }
        },
        MuiTableCell:{
            root:{
                padding: "8px"
            }
        },
        MuiDialog:{
            paperWidthSm:{
              maxWidth: "100%"
            }
           
        },
        MuiPaper:{
            rounded:{
                borderRadius:"0"
            }
        },
        MuiTab:{
            labelIcon:{
                minHeight:'60px',
              paddingTop: '0px'
            },
            root:{
              padding: '0px'
            },
            wrapper:{
                flexDirection: "row",
            },
        },
        MuiSnackbar:{
            anchorOriginTopCenter: {
                top: "50%",
                transform: "translateY(-50%)",
                '@media (min-width: 600px)': {
                    top: "50%",
                    transform: "translateX(-50%)"
                },
            }
        }

    },
},ruRU);


