import ReactDOM from 'react-dom';
import App from './App'
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/integration/react'
import Reducer from './redux'
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import {createStore, applyMiddleware} from 'redux';
import { BrowserRouter as Router } from "react-router-dom"
import thunk from "redux-thunk"
import {composeWithDevTools} from 'redux-devtools-extension';
import UserProvider from "./context/UserContext"
import AlertProvider from './context/AlertContext'
import './index.css';
import 'react-phone-input-2/lib/style.css'
import './styles/main.scss'
import {QueryClient, QueryClientProvider} from "react-query";
// import ExportExcelProvider from "./context/ExportExcelContext";

const persistConfig = {
    key: 'root',
    storage
}

const persistedReducer = persistReducer(persistConfig, Reducer)

let store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)))

let persistor = persistStore(store)

const queryClient = new QueryClient();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router>
                <UserProvider>
                    <AlertProvider>
                        <QueryClientProvider client={queryClient}>
                            <App/>
                        </QueryClientProvider>
                    </AlertProvider>
                </UserProvider>
            </Router>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
