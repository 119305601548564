import  {useContext,memo} from 'react';
import {useDispatch, useSelector} from "react-redux"
import style from "./header_style.module.scss"
import Avatar from '@material-ui/core/Avatar';
import {UserContext} from "../../context/UserContext"
import {SetUserInfoPopup} from "../../redux/StateOfStyleObjects"
import {useBalance} from "../../hooks/balance"
import SupportHelperText from "../support_helper/SupportHelperText";
function getAvatarImg(avatar) {
    return process.env.REACT_APP_HTTP_SERVER +"/" +  avatar
}
//TODO Настройки - просмотреть  пункт в будущем
const Header = () => {
    const {userInfo} = useContext(UserContext)
    const userInfoPopup = useSelector(state => state.styleState.userInfoPopup)

    const balance = useBalance(userInfo.company?.inn)
    
    const dispatch = useDispatch()
    return (
        <div className={style.main}>


                <div className={style.flex}>
                    <img src="/logo.png" alt="logo" className={style.logo}/>

                </div>



            <div className={style.clientInfo}>
                {
                    balance && (
                        <div style={{fontWeight: "500" }}>
                            Баланс счета: <span style={{color: balance !== 0 ? "white" : "#FA4866"}}>{balance} &#8381;</span>
                        </div>
                    )
                }
                <div style={{cursor: "pointer", marginRight:"15px"}} onClick={() => {
                    dispatch(SetUserInfoPopup(!userInfoPopup))
                }}>
                    <Avatar src={userInfo.avatar !== null ? getAvatarImg(userInfo.avatar) : '/ico_user.png'} />
                </div>
                <div className={`${style.flexColumn} `}>
                    <span> {userInfo.firstName} {userInfo.lastName}</span>
                    <span>{userInfo.company.name}</span>
                </div>
            
            </div>
        </div>
    );
};

export default memo(Header);