export const SET_USER_DATA = "SET_USER_DATA"
export const DELETE_USER_DATA = "DELETE_USER_DATA"
export const SET_LOGIN = "SET_LOGIN"
export const SET_TOKEN = "SET_TOKEN"


export const defaultState = {
    login: false,
    token: null
}



const userReducer = (
    state = defaultState,
    action
) => {
    switch (action.type) {
        case SET_USER_DATA: {
            return {
                ...state,
                secondName: action.payload.secondName,
                name: action.payload.name,
                login: action.payload.login,
                role: action.payload.role || "client",
                userId: action.payload.userId || "def"
            }
        }
        case DELETE_USER_DATA: {
            state = defaultState
            return {
                state
            }
        }
        case SET_LOGIN:{
            return {
                ...state,
                login: action.payload

            }
        }
        case SET_TOKEN:{
            return {
                ...state,
                token: action.payload
            }
        }
        default:
            return state
    }
}


export const setUserData = (userData) => {
    return {type: SET_USER_DATA, payload: userData}
}
export const deleteUserData = () => ({type: DELETE_USER_DATA})

export const setToken = (token) => ({type: SET_TOKEN, payload: token})

export const setLogin = (login) =>({type:SET_LOGIN, payload: login})

export default userReducer;