import {SetAutomobilePackType, SetCargo, SetCargoDanger,SetCargoTransportDirection,selLoading, SetAutomobileType} from "./automobileInstanse"
import {SetPartner,SetDrivers,SetLoadingGeneralInfo,SetAutomobileTransport, SetForwardAgents} from "./generalinfofromilsar"
import instanceServerApi from "../AxiosInstanse/serverApi"

const actions = [ "AutomobilePackType", "CargoDanger", "CargoTransportDirection", "Cargo","AutomobilePassportType"]
const generalActions = ["Partner", "AutomobileDriver","AutomobilePassport","ForwarderAgent"]
const ilsarUserInfo = ["AutomobileDriver","AutomobilePassport","AutomobilePassportType"]
export const setAutomobileDirectory = () => (dispatch,getState) =>{
    let promises = []
    actions.map(action=>promises.push(instanceServerApi.get(`/api/services/ilsar/get?param=odata/${action}`)))
    Promise.all(promises).then(result=>{
    
        const packType = result[0].data.response.map(item=>{return {value: item.Oid, label: item.FullName + " Код:" + item.Code}})
        dispatch(SetAutomobilePackType(packType))

        const danger = result[1].data.response.map(item=>{return {value: item.Oid, label: item.FullName + " Код:" + item.Code}})
        dispatch(SetCargoDanger(danger))

        const direction = result[2].data.response.map(item=>{return {value: item.Oid, label: item.FullName}})
        dispatch(SetCargoTransportDirection(direction))

        const cargo = result[3].data.response.map(item=>{return {value: item.Oid, label: item.FullName + " Код:" + item.Code}})
        dispatch(SetCargo(cargo))

        // const containers = result[4].data.response.map(item=>{return {value: item.Oid, label: item.Number}})
        // dispatch(SetContainerPassport(containers))
         const automobileType = result[4].data.response.map(item=>{return {value: item.Name, label: item.Name}})
        dispatch(SetAutomobileType(automobileType))

        dispatch(selLoading())
    })
}

export const setGeneralInfoFromILSAR = () => (dispatch)=>{
    let promises = []
    generalActions.map(action=>promises.push(instanceServerApi.get(`/api/services/ilsar/get?param=odata/${action}`)))
    Promise.all(promises).then(result=>{
        const partner = result[0].data.response.map(item=> {return {value: item.Oid, label: item.FullName, CodeMDG: item.CodeMDG}})
        dispatch(SetPartner(partner))
        const drivers = result[1].data.response.map(item=> {return {value: item.Oid, label: item.Name}})
        dispatch(SetDrivers(drivers))
        const autopassport = result[2].data.response.map(item=> {return {value: item.Oid, label: item.Number}})
        dispatch(SetAutomobileTransport(autopassport))
        const agent = result[3].data.response.map(item=> {return {value: item.Oid, label: item.ShortName}})
        dispatch(SetForwardAgents(agent))
        dispatch(SetLoadingGeneralInfo())


    })
}

export const setIlsarUserInfo= () => (dispatch) =>{
    let promises = []
    ilsarUserInfo.map(action=>promises.push(instanceServerApi.get(`/api/services/ilsar/get?param=odata/${action}`)))
    Promise.all(promises).then(result=>{
        const drivers = result[0].data.response.map(item=> {return {value: item.Oid, label: item.Name}})
        dispatch(SetDrivers(drivers))
        const autopassport = result[1].data.response.map(item=> {return {value: item.Oid, label: item.Number}})
        dispatch(SetAutomobileTransport(autopassport))
        const automobileType = result[2].data.response.map(item=>{return {value: item.Name, label: item.Name}})
        dispatch(SetAutomobileType(automobileType))
    })

}