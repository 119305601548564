import React, {useEffect, useState} from 'react'
import './view_news_style.scss'
import instanceServerApi from "../../AxiosInstanse/serverApi";
import NotFound from "../../components/NotFound/NotFound";


function ViewNewsById() {
    const [news, setNews] = useState("")
    useEffect(()=>{
        const url = window.location.href.split('/').pop()
        instanceServerApi.get('/api/other/news',{
            params: {
                pagination : {"current":1,"limit":3},
                sort: {"date":"desc"},
                search: {"url":url}
            }
        }).then(res=> {
            const news_page = res.data.response[0].body
            const doc = document.querySelector('#news_root')
            doc.innerHTML = news_page
        }).catch(err=> {
            setNews(null)
            console.log(err)
        })

    },[])

  return (
    <div id={"news_root"}>
        {news == null && (
            <NotFound />
        )}
    </div>
  )
}

export default ViewNewsById