export default  function  (buffer,closeAlert,title) {

    const binary = new Uint8Array(buffer)
    let blob = new Blob([binary], {type: "application / vnd.ms-excel"}); // Затем создайте объект blob и установите тип файла для excel
    let blobURL = window.URL.createObjectURL(blob); // Затем создайте доступный URL
    let tempLink = document.createElement('a'); // Создать тег для загрузки
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', `${title}.xlsx`);
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
    closeAlert()
}