export const helper_text_sed = (inn='ИНН', FIO="Мы не нашли ФИО подписанта") =>  {
    return ( {
        text: `<ul>
                <li>Подпись выдана на генерального директора компании</li>
                <li>Инн компании в ЭЦП соответствует ИНН присвоенной у вас компании:(${inn})</li>
                <li>ФИО в ЭЦП соответствует ФИО подписанта: (${FIO.toUpperCase()})</li>
           </ul>`
    })

}

export const helper_text_sed_new_dogovor = (inn='ИНН', FIO="Мы не нашли ФИО подписанта") =>  {
    return ( {
        text: `<ul>
                <li>Подпись выдана на генерального директора компании</li>
                <li>Подпись должана быть установлена в личный контейнер сертификатов</li>
                <li>Инн компании в ЭЦП соответствует ИНН присвоенной у вас компании:(${inn})</li>
                <li>Фамилия в ЭЦП соответствует фамилии подписанта в договоре: (${FIO.toUpperCase()})</li>
           </ul>`
    })

}