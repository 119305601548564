import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({

    paper :{
        width:"70%"
    }
}));


function PreviewAlertsComponents(props) {
    const [open, setOpen] = React.useState(true);
    const classes = useStyles()
    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseLocal = () => {
        setOpen(false);
        localStorage.setItem(props.info.id, "true")
    };
    return (

            <Dialog
                classes={{paper: classes.paper}}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id={props.info.id}>{props.info.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.info.text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseLocal} color="primary">
                        Больше не показывать
                    </Button>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
    );
}

export default PreviewAlertsComponents;