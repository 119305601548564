import React, {useContext, useEffect, useState} from 'react';
import NewContractForm from "../../Dashboard/SED/NewContractForm";
import {MainPage} from "../../modules/sed_documents";
import {ConfirmationForm} from '../../modules/sed_documents'
import Accordion from 'react-bootstrap/Accordion';
import BigTitle from "../../components/FontComponent/BigTitle";
import {UserContext} from "../../context/UserContext";
import instanceServerApi from "../../AxiosInstanse/serverApi";
import AlertCompanyError from "./AlertCompanyError";


function SedNewDogovorPage(props) {
    const {userInfo} = useContext(UserContext)
    const [error,setError] = useState(null)
    useEffect(()=>{
        instanceServerApi.post( '/api/services/bitrix/post', {
            "url": "crm.requisite.list",
            "body": {
                "filter":{ "RQ_INN": userInfo.company?.inn, "RQ_KPP": userInfo.company?.kpp},
                "select": ['*']
            }
        }).then(res => {
            if(res.data.response.length <= 0) {
                setError('company')
                return
            }
            const ID = res.data.response[0].ENTITY_ID
            if(ID){

            }
            instanceServerApi.post(process.env.REACT_APP_HTTP_SERVER + '/api/services/bitrix/deallist', {
                order: {"DATE_CREATE": "DESC"},
                filter: {'COMPANY_ID': ID, "CATEGORY_ID":"74"},
                select: ['*', 'UF_*']
            }).then(response => {
                let checking = null
                if(response.data.response?.length <=0) {
                    setError("category")
                    return
                }else {
                    response.data.response.forEach(deal=>{
                        if(deal.STAGE_ID === "C74:WON") {
                            checking = true
                            setError(false)
                            return
                        }
                    })
                }

                if(!checking) { setError("category") }


            })

        }).catch(err => console.log(err))
    },[])
    return (
        <>
                <div>
                    <BigTitle>Страница формирования договора</BigTitle>
                    {error === "company" && (
                        <p>
                            Мы не нашли вашу компанию в системе Bitrix. Пожалуйста нажмите на кнопку, чтобы мы создали компанию в системе с данными вашей компании.
                            Это нужно для последующией отправки запроса на заключение договора.
                        </p>
                    )}
                    {(error === "category" && userInfo.company.isNew) && (
                        <>
                            <p>Мы не нашли заявку на заключение договора в системе Bitrix от вашей компании</p>
                            <p>Просьба перейти в пункт меню "Запросы" -> нажать на кнопку "Создать новый запрос" </p>
                            <p>В появившиемся окне выбрать  в пункте "Порт" выбрать КТСП</p>
                            <p>В пункте тема обращения выбрать "Заявка на заключение договора"</p>
                            <p>Скачайте и заполните шаблон "Анкета контрагента"</p>
                            <p>Прикрепите заполненную анкету</p>
                            <p>Отправьте запрос</p>
                        </>
                    )}
                    {(error === false && userInfo.company.isNew) && (
                        <>
                            {userInfo.company.id.length > 12 && (
                                <AlertCompanyError />
                            )}
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Данные для фомирования договора</Accordion.Header>
                                    <Accordion.Body>
                                        <NewContractForm />
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Подпись договора</Accordion.Header>
                                    <Accordion.Body>
                                        <MainPage />
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <ConfirmationForm />
                        </>
                    )}
                </div>
            {!userInfo.company.isNew && (
                <p>У вашей компании уже есть договор на информационные услуги с ЗАО КСТП</p>
            )}
        </>

    );
}

export default SedNewDogovorPage;