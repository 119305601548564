import React, {useEffect, useState} from 'react';
import instanceServerApi from "../../AxiosInstanse/serverApi";
import NotificationsCard from "../../components/start_page_components/NotificationsCard";
import BigTitle from "../../components/FontComponent/BigTitle";


function NotificationsList({open,handleClose}) {
    const[info,setInfo] = useState([])

    useEffect(()=>{
        instanceServerApi.get("/api/other/notifications",{
            params:{
                sort:{"dateIn":"asc"}
            }
        }).then(res=>{
            if(Array.isArray(res.data.response)) setInfo(res.data.response)
        }).catch(err=>{
            console.log(err)
        })
    },[])

    return (
        <>
            <BigTitle>Все уведомления</BigTitle>
            <div style={{
                margin: "0 auto",
                display:"flex",
                flexDirection:"column",
                gap:"30px",
                width:"70%",
                justifyContent:"space-between"
            }}>
                {info.map(item=>(
                    <NotificationsCard item={item} />
                ))}
            </div>

        </>

    );
}

export default NotificationsList;