
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;


export default function createPdfBinary(docDefinition, callback) {

    pdfMake.fonts = {
       Times: {
            normal: 'timesnewromanpsmt.ttf',
            // normal: `${process.env.REACT_APP_HTTP_SERVER}/fonts/timesnewromanpsmt.ttf`
            // normal: `http://localhost:3000/fonts/timesnewromanpsmt.ttf`
            
       }
    }    

      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
     
      pdfDocGenerator.getBuffer((buffer) => {
        callback(buffer);
      });
	
}

