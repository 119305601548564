import { PDFDocument , layoutMultilineText } from 'pdf-lib'
import fontkit from  '@pdf-lib/fontkit' 
import header_content from './pdf-header-content'
import createPdfBinary from './pdf-header-creater'
import instanceServerApi from "../../AxiosInstanse/serverApi"





export default async function createPDFDocument(props,setFileBase64,setSignatureBase64,company){
    setSignatureBase64(null, '')
    const short_name = props.firstNameRod.charAt(0) + "." + props.secondNameRod.charAt(0) + ". " + props.lastName

    const res = await  instanceServerApi.get('/api/services/sed/documents/last')

    const number_doc = res.data.response[0].number === null ? 1 : Number(res.data.response[0].number) + 1
    let requisitiesMulti = []


    const requisities = [
        `${props.company_name}`,
        `Юридический и фактический адрес: ${props.legal_address}`,
        `ИНН/КПП:${company.inn} / ${company.kpp}`,
        `ОГРН:${props.ogrn}`,
        `Тел.: ${props.phone_number}`,
        `Банковские реквизиты: 
${props.bank_name}`,
        `Расчетный счет № ${props.summer}`,
        `Корр.счет № ${props.korr_summer}`,
        `БИК ${props.bik}`,
        `Адрес банка: ${props.bank_address}`
      ]

    
    const dd =header_content(props);
    
    const url = `/dogovor_na_informacionnye_uslugi_2023.pdf`
    // const existingPdfBytes = await fetch(`${process.env.REACT_APP_HTTP_SERVER}/assets/dogovor_new.pdf`).then(res => res.arrayBuffer()).catch(err=> console.log(err))
    const existingPdfBytes = await fetch(`${url}`).then(res => res.arrayBuffer()).catch(err=> console.log(err))


    

    const document = await PDFDocument.load(existingPdfBytes);

    document.registerFontkit(fontkit)

    const formatter = new Intl.DateTimeFormat('ru', {day:"numeric", month: 'numeric', year: "numeric" });
    // const formatter_month = new Intl.DateTimeFormat('ru', {month: 'long' });
    const formatter_month = new Intl.DateTimeFormat('ru', {year: "numeric",month: "long",day: "numeric"});
    const date_doc = formatter.format(new Date());
    const month_long = formatter_month.format(new Date())
    const [date,month,year] = date_doc.split(".")




    const firstPage = document.getPage(0)
    const sixPage = document.getPage(5)
    const ten = document.getPage(9)
    const eleven = document.getPage(10)
    const thirteen = document.getPage(12)
    
    // const fontBytes = await fetch(`${process.env.REACT_APP_HTTP_SERVER}/assets/timesnewromanpsmt.ttf`).then(res => res.arrayBuffer())
    const fontBytes = await fetch(`/fonts/timesnewromanpsmt.ttf`).then(res => res.arrayBuffer())
    const fontBytesBold = await fetch(`/fonts/timesnewromanbold.ttf`).then(res => res.arrayBuffer())

    const customFont = await document.embedFont(fontBytes);
    const customBoldFont = await document.embedFont(fontBytesBold)
    const { width, height } = firstPage.getSize()
    // console.log(width,height)
    const document_name = `ИУ-ЛКК ${number_doc}/${year.substr(2,2)}`

    document.getPages().forEach((page,index)=>{
        if(index === 0) {
            page.drawText("ДОГОВОР № " + document_name,{
                font: customBoldFont,
                x: 235,
                y: 800,
                size: 11
            })
            //
            // page.drawText(`«${date}» ${month_long} ${year}г.`,{
            //     font: customFont,
            //     x: 380,
            //     y: 768,
            //     size: 11
            // })
            page.drawText(`${month_long}`,{
                font: customFont,
                x: 420,
                y: 760,
                size: 11
            })
        }

        if(index===11) {
            page.drawText(`к договору № ИУ-ЛКК ${number_doc}/${year.substr(2,2)} от ${date}.${month}.${year}`,{
                font: customBoldFont,
                x: 365,
                y: 770,
                size: 11
            })
        }


        if (index !== 0) {
            page.drawText(`ДОГОВОР № ИУ-ЛКК ${number_doc}/${year.substr(2,2)} от ${date}.${month}.${year}`,{
                font: customBoldFont,
                x: 200,
                y: 820,
                size: 11
            })
        }

    })



    function multiLine(arrayText) {
      for(let i=0; i<arrayText.length; i++){
        const multiText = layoutMultilineText(arrayText[i], {
          font: customFont,
          fontSize: 11,
          bounds: { width: 240}
        })
        requisitiesMulti.push(multiText)
      } 
    }
    multiLine(requisities)


    const multiFullRole = layoutMultilineText(props.full_role, {
        font: customFont,
        fontsize: 11,
        bounds: { width: 200}
      })

    
    createPdfBinary(dd, async function (binary) {
          const header = await PDFDocument.load(binary);
          const preamble = await document.embedPage(header.getPages()[0], {
            left: 0,
            bottom: 600,
            right: 700,
            top: 800,
          });
    
          firstPage.drawPage(preamble,{
            x: 0,
            y: 540
          })

        sixPage.drawText(props.email,{
            font: customBoldFont,
            size: 11,
            x: 78,
            y: 621
          })

        sixPage.drawText(props.email_buh,{
            font: customBoldFont,
            size: 11,
            x: 43,
            y: 557
          })

        ten.drawText(props.email,{
            font: customBoldFont,
            size: 11,
            x: 200,
            y:393
          })
          let requisities_x = 310;
          let requisities_y = 670;


        eleven.drawText(props.full_role, {
            x: 315,
            y: 370,
            font: customBoldFont,
            size: 11,
            maxWidth: 260,
            wordBreaks: [" "],
            lineHeight: 11 * 1.1,
        })


          // for(let i=0; i<multiFullRole.lines.length;i++){
          //   let y = 370
          //   y = y - (13 * i)
          //     eleven.drawText(multiFullRole.lines[i].text, {
          //       x: 315,
          //       y: y,
          //       font: customBoldFont,
          //       size: 11
          //     })
          //
          // }

        eleven.drawText("_________________________" + short_name, {
            x: 315,
            y: 317,
            font: customBoldFont,
            size: 11
          })

        thirteen.drawText(props.full_role,{
            font: customBoldFont,
            size: 11,
            x: 316,
            y:110,
            maxWidth: 260,
            wordBreaks: [" "],
            lineHeight: 11 * 1.1,
        })
          // for(let i=0; i<multiFullRole.lines.length;i++){
          //   let y = 110
          //   y = y - (13 * i)
          //     thirteen.drawText(multiFullRole.lines[i].text,{
          //       font: customBoldFont,
          //       size: 11,
          //       x: 316,
          //       y:y
          //     })
          //
          // }

        thirteen.drawText("_________________________" +short_name,{
            font: customBoldFont,
            size: 11,
            x: 316,
            y:75
          })


          for(let i=0; i<requisitiesMulti.length;i++){
            for(let j=0; j<requisitiesMulti[i].lines.length; j++){
                eleven.drawText(requisitiesMulti[i].lines[j].text, {
                x: requisities_x,
                y: requisities_y,
                font: i===0 ?customBoldFont : customFont,
                size: 11
              })
                requisities_y = requisities_y - 15
            }
          }




        const pdfBytes = await document.save()
        const base64File = await document.saveAsBase64()
        setFileBase64(base64File,document_name)
        const file = new Blob([pdfBytes], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        // if(file) {
        //     instanceServerApi.post('/api/services/sed/documents',{
        //         "year": Number(year.substr(2,2)),
        //         "number": number_doc + "",
        //         "emails": `${props.email},${props.email_buh}`,
        //         "post": "Договор на оказание информационных услуг",
        //         "settlement": props.summer,
        //         "correspondent": props.korr_summer
        //     })
        // }
        window.open(fileURL);

    
    }, function (error) {

      return null
    });
  
  
}