import React, {Suspense} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import {roles} from "../constants/AuthRole";
import PrivateRoleRoute  from "../components/HOC/PrivateRoleRoute"
import ViewNewsById from "../pages/view_news";
import AllNewsList from "../pages/view_news/AllNewsList";
const UserList = React.lazy(()=> import("../Dashboard/Admin/UsersList/UserList"))
const LogsTable  = React.lazy(()=> import("../Dashboard/Admin/UsersList/LogsTable"))
const UserInfoForAdmin= React.lazy(()=> import("../Dashboard/Admin/UsersList/UserInfoForAdmin"))
const NotFound = React.lazy(()=> import('../components/NotFound/NotFound'))
const ManagerQuestionsDashboard = React.lazy(()=> import("../Dashboard/Tasks/ManagerQuestionsDashboard"))
const AdminApplication = React.lazy(()=>import('../pages/admin_applications_for_doc/AdminApplicationsForDocs'))
const AdminNewsPage = React.lazy(()=>import('../pages/news'))
const AdminNotifications = React.lazy(()=>import('../pages/admin_notification'))
const BalanceLogs = React.lazy(()=>import('../pages/balance_logs'))
const NotificationsBalancePage = React.lazy(()=>import('../pages/notifications_balance_config_page'))
const AdminRoute = () => {

    return (
        <Suspense fallback={<div>...Загрузка</div>}>
            <Switch>
                {/*<Route exact path='/dashboard/manager-questions/:id' component={QuestionsInfo}/>*/}
                <PrivateRoleRoute exact path='/dashboard' component={UserList} role={[roles.admin]}/>
                <PrivateRoleRoute exact path='/dashboard/users_logs' component={LogsTable} role={[roles.admin]}/>
                <PrivateRoleRoute exact path='/dashboard/admin/user/:id' component={UserInfoForAdmin} role={[roles.admin, roles.manager]} />
                <PrivateRoleRoute path='/dashboard/manager-questions' component={ManagerQuestionsDashboard} role={[roles.manager, roles.admin]}/>
                <PrivateRoleRoute exact path='/dashboard/news_create' component={AdminNewsPage} role={[roles.manager, roles.admin]}/>
                <PrivateRoleRoute exact path='/dashboard/alerts_create' component={AdminNotifications} role={[roles.manager, roles.admin]}/>
                <PrivateRoleRoute exact path='/dashboard/news/:id' component={ViewNewsById}  />
                <PrivateRoleRoute exact path='/dashboard/news' component={AllNewsList}  />
                <PrivateRoleRoute exact path='/dashboard/balance_logs'  component={BalanceLogs}/>
                <PrivateRoleRoute exact path='/dashboard/setting_notifications_balance'  component={NotificationsBalancePage}/>
                 {/*<PrivateRoleRoute exact path='/dashboard/admin-applications' component={AdminApplication} role={[roles.admin]}/>*/}
                <Route path='*' component={NotFound}/>
            </Switch>
        </Suspense>
    );
};

export default AdminRoute;