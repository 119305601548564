import React, {Suspense} from 'react';
import {Route, Switch} from "react-router-dom";
import NotFound from "../components/NotFound/NotFound";
import PrivateRoleRoute from "../components/HOC/PrivateRoleRoute";
import {roles} from "../constants/AuthRole";
const WagonsPagesList = React.lazy(()=>import('../pages/admin_wagons'))
const WagonViewAdmin = React.lazy(()=>import('../pages/wagon_view_for_admin'))

function AdminWagonRoutes() {
    return (
        <Suspense fallback={<div>Загрузка</div>}>
            <Switch>
                <PrivateRoleRoute exact path='/dashboard/wagons_page' component={WagonsPagesList} role={[roles.admin_wagon]}/>
                <PrivateRoleRoute exact path='/dashboard/wagon_view/:id' component={WagonViewAdmin} role={[roles.admin_wagon]}/>
                <Route path='*' component={NotFound}/>
            </Switch>
        </Suspense>
    );
}

export default AdminWagonRoutes;