import React from 'react';
import {ReactSVG} from "react-svg"
import style from './assets_style.module.scss'
import PropTypes from 'prop-types'

const ButtonWithicon = ({id,handle,icon, children,iconType,handleProps,width,reverse,cancel,type,disable}) => {
    // const {handle,icon, children,iconType,handleProps} = props
    if(iconType !== "left")
        return (
            <button disabled={disable} id={id} type={type} style={{width: width}} className={`${style.sample_button}  ${cancel ? style.sample_button_cancel: style.sample_button_def } `} onClick={()=>{
               if(handle!==null) handle(handleProps)
            }}>
                {children && (
                    <span>
                        {children}
                    </span>
                )}
                {icon && (
                    <ReactSVG className={reverse? style.reverse: ""}  src={icon} wrapper="div"/>
                )}
            </button>
        );
    else
        return (
            <button disabled={disable} id={id} type={type} style={{width: width}} className={`${style.sample_button}  ${cancel ? style.sample_button_cancel: style.sample_button_def} `} onClick={()=>{
                if(handle!==null) handle(handleProps)
            }}>
                {icon && (
                    <ReactSVG className={reverse? style.reverse: ""}  src={icon} wrapper="div"/>
                )}
                {children && (
                    <span>
                        {children}
                    </span>
                )}

            </button>
        );
};

ButtonWithicon.defaultProps ={
    type: 'button',
    width: 'auto',
    handle: null,
    handleProps: null,
    iconType : "left",
    icon: null,
    disable: false
}

ButtonWithicon.propsType ={
    handle: PropTypes.func,
    icon: PropTypes.string,
    children:PropTypes.string,
    iconType: PropTypes.string,
    handleProps: PropTypes.object
}

export default ButtonWithicon;