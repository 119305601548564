import React, {useEffect, useState} from 'react';
import PreviewAlertsComponents from "./PreviewAlertsComponents";
import instanceServerApi from "../../AxiosInstanse/serverApi";



const getData=async ()=>{
   const data = await fetch('/alerts.json',{
            cache: 'no-store',
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    ).then(function(response){
            return response.json();
    })
        .then(function(myJson) {
            return (myJson);
        });
   return data
}


function PreviewALerts(props) {
    const [data,setData] = useState([])
    const [previewList, setPreviewList] = useState([])

    useEffect(()=>{
       getData().then(data_json=>{
           if(Array.isArray(data_json) && data_json?.length > 0) {
               setData(data_json)
           }
       }).catch()
    },[])

    useEffect(()=>{
        const prevList = []
        data.forEach(alert=>{
            const id = localStorage.getItem(alert.id)
            if(!id || id == false){
                prevList.push(alert)
            }
        })
        setPreviewList(prevList)

    },[data])


    return (
        <div>
            {
                previewList.map((item)=>(
                    <PreviewAlertsComponents info={item} />
                ))
            }
        </div>
    );
}

export default PreviewALerts;