import React, {useContext} from 'react';
import {BrowserRouter as Switch, Redirect, Route} from "react-router-dom";
import {roles} from "../constants/AuthRole";
import {PATHES} from "../constants/RoutesPath";
import {UserContext} from "../context/UserContext";


const RedirectRoutes = ({userInfo}) =>{
    if (userInfo.role === roles.ilsar) return (
        <Redirect to={PATHES.PATH_TO_ILSAR_WORKER}/>
    )
    if(userInfo.role === roles.wagon) return (
        <Redirect to={PATHES.PATH_TO_WAGONS_WORKER}/>
    )
    if ([roles.client, roles.mainClient].includes(userInfo.role)) return (
        <Redirect to={PATHES.PATH_TO_DASHBOARD}/>
    )
    if(userInfo.role === roles.admin_wagon) return (
        <Redirect to={PATHES.PATH_TO_WAGONS_ADMIN_PAGE}/>
    )
    if(userInfo.role === roles.admin_ktsp_incoming) return (
        <Redirect to={PATHES.PATH_TO_ADMIN_KTSP_INCOMING}/>
    )
    if (userInfo?.role === roles.admin) return <Redirect to={PATHES.PATH_TO_DASHBOARD}/>
}

const RedirectComponent = () => {
    const {userInfo} = useContext(UserContext)

    return (
        <>

            <Route exact path={'/'}>
               <RedirectRoutes userInfo={userInfo} />
            </Route>
            <Route exact path={'/login'}>
                <RedirectRoutes userInfo={userInfo} />
            </Route>

        </>
    );
};

export default RedirectComponent;