import style from './news_card.module.scss'
import {useEffect} from "react";
import {dateIn} from "../../lib/utils/getDate";
import {useHistory} from "react-router";

function NewsCard({img,title,date,titleWeb}) {
    const history = useHistory()
    useEffect(()=>{
        if(img!==null && img!=="") {
            const img_div = document.querySelector('#image_'+titleWeb)
            img_div.innerHTML = img
        }
    },[])

    const handleLinkPage = (titleWeb)=>{
        history.push('/dashboard/news/'+titleWeb)
    }
    return (
        <div className={style.container}>
            <article className={style.article_full}>
                <div className={style.card__bg_link}  onClick={()=>{
                    handleLinkPage(titleWeb)
                }} />
                <div id={"image_"+titleWeb} className={(img ==null || img === "") ? style.default_picture : ""}>
                    {(img ==null) && (
                        <img src={"/icons/ico_logo_ru.svg"} />
                    )}
                    {(img ==="") && (
                        <img src={"/icons/ico_logo_ru.svg"} />
                    )}
                </div>
                <time>{dateIn(date)}</time>
                <div data-type="title">{title}</div>
                <p data-type="link" className={style.card__link}>ПОДРОБНЕЕ</p>
            </article>
        </div>
    );
}

export default NewsCard;