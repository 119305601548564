import { combineReducers } from 'redux';
import userReducer from "./userSession"
import StateOfStyleObject from "./StateOfStyleObjects"
import automobileInstance from './automobileInstanse'
import generalInfo from './generalinfofromilsar'


export default combineReducers({
        GeneralInfoFromILSAR: generalInfo,
        AutoMobileInstanseInfo: automobileInstance,
        styleState: StateOfStyleObject,
        user: userReducer,
    })


