import {useContext} from 'react'
import {UserContext} from '../../context/UserContext'
import style from './userInfoStyle.module.scss'
function FilesUser() {
  const {userInfo} = useContext(UserContext)  
  return (
    <div>
        <h4>Файлы пользователя</h4>
        {userInfo.files?.length > 0 && (
            <>
                {userInfo.files.map((file,index)=>(
                    <div className={style.files_item} key={file.path} >
                        <a href={process.env.REACT_APP_HTTP_SERVER + '/file-server/user-file?path='+ file.path} target="_blank">Файл {index} </a>
                    </div>
                ))}
            </>
        )}
    </div>
  )
}

export default FilesUser