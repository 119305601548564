import {useContext, useEffect,useState} from 'react'
import {checkBitrixContact,createBitrixContact} from '../../lib/services/serviceBitrix'
import {UserContext} from "../../context/UserContext"
import ButtonWithicon from '../../components/shared/ButtonWithicon'

function BitrixUserController(props) {
  const [haveContact, setContatc] = useState(false)
  const {userInfo} = useContext(UserContext)
  useEffect(()=>{
      checkBitrixContact(userInfo.email).then(res=>{
          if (res.data.response.length > 0) setContatc(true) 
      }).catch(e=>{
        console.log(e)
      })
  },[])

  const createContact = () =>{
    const {email,firstName, lastName,secondName,phoneNumber,company} = userInfo
    createBitrixContact(email,firstName,lastName,secondName,phoneNumber,company.id).then(res=>{
      console.log(res)
    }).catch(e=> console.log(e))
  }


  return (
    <div>
      {haveContact && (
         "У вас есть контакт в системе Bitrix24"
      )}
      {!haveContact && (
        <ButtonWithicon handle={createContact}> 
            Создать контакт в Bitrix24
        </ButtonWithicon>
      )}
    </div>
  )
}

BitrixUserController.propTypes = {}

export default BitrixUserController
