import React, {useCallback, useContext, useEffect, useState} from 'react';
import {UserContext} from "../../../context/UserContext";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {getBase64} from "../../../lib/utils/getBase64";
import useDoCertsList from "../../../hooks/certList";
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container';
import style from "../style.module.scss"
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ButtonWithicon from "../../../components/shared/ButtonWithicon";
import Button from 'react-bootstrap/Button';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Card from 'react-bootstrap/Card';
import ccpa from "crypto-pro-cadesplugin";
// import SelectSample from "../../../components/shared/SelectSample";
// import debounce from "lodash/debounce";
import instanceServerApi from "../../../AxiosInstanse/serverApi";
import {useUpdateIncomingLetterLKKStore} from "../store/update_incoming_letter_store";
import Modal from 'react-bootstrap/Modal';
import CertSelect from "./CertSelect";

function dataURItoBlobToUpload(dataURI, callback) {
    const byteString = atob(dataURI);

    const ab = new ArrayBuffer(byteString.length);
    // const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ab[i] = byteString.charCodeAt(i);
    }
    // const ia = new Uint8Array(ab);

    return new Blob([byteString]);
}

function DataURIToBlob(dataURI) {
    const byteString = atob(dataURI)
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i)

    return new Blob([ia])
}


export async function downloadSignCert(cert, letter, letterName = "входящее письмо") {
    const certsApi = await ccpa();
    const sign = await certsApi.signBase64(cert, letter);
    // setSignatureBase64(sign, `подпись документа_${letterName}`)

    const blob = DataURIToBlob(sign)
    let blobURL = window.URL.createObjectURL(blob); // Затем создайте доступный URL
    let tempLink = document.createElement('a'); // Создать тег для загрузки
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', `подпись документа_${letterName.split('.')[0]}.sig`);
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);

    return sign
}

function checkFileType(fileName) {
    switch (fileName.split(".")[1]) {
        case "doc":
        case "docx":
            return '/doc_type/doc.png'
        case "pdf":
            return '/doc_type/pdf.png'
        case "png":
        case "jpg":
        case "jpgeg":
            return '/doc_type/image.png'
        default:
            return '/doc_type/word.png'
    }

}

const ModalDeleteFile = ({close,file,deleteFile})=>{
    return (
        <Modal show={true}  onHide={close} animation={false}>
            <Modal.Header closeButton>
                <Modal.Title>Удаление файла</Modal.Title>
            </Modal.Header>
            <Modal.Body>Вы действительно хотите удалить файл {file.name}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={close}>
                    Отмена
                </Button>
                <Button variant="warning" onClick={()=>{
                    deleteFile(file.id)
                }}>
                    Удалить
                </Button>
            </Modal.Footer>
        </Modal>
    )
}


function UpdateLetterForm(props) {
    const {userInfo} = useContext(UserContext)

    const {data,setFile,setPreview,setParam,setFilesMap } = useUpdateIncomingLetterLKKStore()
    const [cert, setSert] = useState(null)
    const [disable, setDisable] = useState(false)
    const [companyList, setCompanyList] = useState(null)
    const [id_letter,setIdLetter]= useState(null)
    const [update,setUpdate] = useState(false)
    const [show,setShow] = useState(false)
    const [fileDelete,setFileDelete] = useState(null)

    const selectCertHandle = useCallback((value)=>{
        setSert(value)
    },[])

    const deleteFiles = (fileName)=>{
        const new_files = Array.from(data.files).filter(file=>file.name !== fileName)
        setFilesMap(new_files)
    }

    const forceUpdate = ()=>{
        setUpdate(state=> !state)
    }
    const deleteAttachments = (id)=>{
       instanceServerApi.delete(`/api/services/sed/incoming/file/${id}`).then(res=>{
           forceUpdate()
           setFileDelete(null)
           setShow(false)
       }).catch(err=>{
           setFileDelete(null)
           setShow(false)
           alert(err)
       })
    }

    const deleteIncoming = (id)=>{
        instanceServerApi.delete(`/api/services/sed/messages/${id}`, {
            data:{
                "isDeleted": !data.isDeleted
            }
        }).then(res=>{
            forceUpdate()
        }).catch(err=>{alert("Ошибка при удалении")})
    }

    const setSignFile = (file) => {
        getBase64(file, async (base64) => {
            await downloadSignCert(cert, base64, file.name)
        })
    }

    // useDoCertsList(userInfo.company.inn).then((list) => setListSert(list)).catch(err => alert("Плагин недоступен. Для подписание ЭЦП установите или проверьте работу CryptoPro WebPlugin. Возможно мы не нашли подписей доступных для подписания"))
    useEffect(()=>{
        const id = window.location.href.split('/').pop()
        setIdLetter(Number(id))
        instanceServerApi.get("/api/services/sed/messages",{
            params:{
                filter:{id:Number(id)}
            }
        }).then(res=>{
            const defaultCompany = [{
                value: res.data.response[0].companyId,
                label: res.data.response[0].company.name
            }]
            setCompanyList(defaultCompany)
            setPreview(res.data.response[0])
        }).catch(err=>{
            alert("Произошла ошибка")
        })
    },[update])

    // useEffect(() => {
    //     console.log(data.files)
    // }, [data.files])


    const sendLetterToLKK = () => {
        setDisable(true)
        // const formData = new URLSearchParams();
        const formData = new FormData()
        const formDataFiles = new FormData()
        const promises = []
        formData.append('summary', data.summary)
        formData.append('title', data.title)
        formData.append('companyId', data.companyId)
        formData.append('sedId', '')
        formData.append('society',"КТСП")
        formData.append('regNumber',data.regNumber)

        Array.from(data.files).forEach(file => {
                const fileName = file.name.split('.')
                formDataFiles.append("attachments", file, encodeURI(fileName[0]) + "." + fileName[1])
        })
        if(data.files.length > 0)  promises.push(instanceServerApi.post(`/api/services/sed/incoming/file/${id_letter}`,formDataFiles))
        promises.push(instanceServerApi.post(`/api/services/sed/messages/${id_letter}`,formData))


        Promise.all(promises).then(res=>{
            forceUpdate()
            setDisable(false)
            setFilesMap([])
        }).catch(err=>alert(err))
    }
    // const handleSetCompany = (value) => {
    //     if (value !== "") {
    //         instanceServerApi.get("/api/guide/companies", {
    //             params: {
    //                 search: {"name": value}
    //             }
    //         }).then(res => {
    //             const companyFiler = res.data.response.filter(item=> item.id.length < 11)
    //             const company = companyFiler.map(item => {
    //                 return {
    //                     value: item.id,
    //                     label: item.name
    //                 }
    //             })
    //             setCompanyList(company)
    //         })
    //     }
    // }

    const handleClose = ()=>{
        setShow(false)
    }



    // const debounceCompany = useCallback(debounce(handleSetCompany, 700), [])

    return (
        <>
            {(show && fileDelete ) && (
                <ModalDeleteFile file={fileDelete} close={handleClose} deleteFile={deleteAttachments} />
            )}

            {disable && (
                <Snackbar
                    anchorOrigin={{vertical: "top", horizontal: "center"}}
                    open={true}>
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        severity={'warning'}
                    >
                        Идет отправка письма
                    </MuiAlert>
                </Snackbar>
            )}
            <Container fluid>
                <Row className={'mb-3'}>
                    <Form.Group as={Col} lg={8}>
                        <Form.Label>Регистрационный номер</Form.Label>
                        <Form.Control value={data.regNumber} type='text' onChange={e => {
                            setParam(e.target.value,"regNumber")
                        }}/>
                    </Form.Group>
                </Row>
                <Row className={'mb-3'}>
                    <Form.Group as={Col} lg={8}>
                        <Form.Label>Тема письма</Form.Label>
                        <Form.Control value={data.title} type='text' onChange={e => {
                            setParam(e.target.value,"title")
                        }}/>
                    </Form.Group>
                </Row>
                {companyList && (
                    <Row className={'mb-3'}>
                        <Form.Group as={Col} lg={8}>
                            <Form.Label>Компания</Form.Label>
                            <Form.Control disabled value={companyList[0].label} type='text'/>
                        </Form.Group>
                    </Row>
                )}

                {/*<Row>*/}
                {/*    <Col lg={8}>*/}
                {/*        <SelectSample width={'100%'} defaultValue={data.companyId}   onInputChange={debounceCompany}  functions={(value)=>{*/}
                {/*                    setParam(value,"companyId")*/}
                {/*        }}  options={companyList}  label={"Выберите компанию"}/>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <Row className={'mb-3'}>
                    <Col lg={8}>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Описание письма</Form.Label>
                                <Form.Control value={data.summary} as="textarea" rows={3} onChange={(e) => {
                                    setParam(e.target.value,"summary")
                                }}/>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
                {data.attachments && (
                <Row className={'mb-3'}>
                    <Col lg={12}>
                        <div className={style.col_form}>
                            <DialogTitle>
                               Раннее прикрепленные файлы
                            </DialogTitle>
                            <DialogContent dividers>
                                <Row  className="g-2">
                                    {Array.from(data.attachments).map(file => (
                                        <Col xl={4} lg={4} md={6} xxl={3}>
                                            <Card style={{width: '14rem'}}>
                                                <Card.Img style={{alignSelf: "center", width: "64px", height: "74px", paddingTop:"10px"}}
                                                          variant="top" src={checkFileType(file.name)}/>
                                                <Card.Body>
                                                    <Card.Title>{file.name}</Card.Title>
                                                </Card.Body>
                                                <Card.Footer style={{display:"flex",justifyContent:"center"}}>
                                                    <Row className="g-4">
                                                        <Button variant="danger" onClick={() => {
                                                            setFileDelete({id: file.id,name: file.name})
                                                            setShow(true)
                                                            // deleteAttachments(file.id)
                                                        }}>Удалить</Button>
                                                    </Row>
                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </DialogContent>
                        </div>
                    </Col>
                </Row>)}
                <Row className={"mb-3"}>
                    <Col lg={8}>
                        <CertSelect handle={selectCertHandle} />
                    </Col>
                </Row>
                <Row className={'mb-3'}>
                    <Col lg={12}>
                        <input multiple={false} id={"letter_file_sed"}
                               name={"letter_file_sed"} type="file" style={{display: "none"}}
                               onChange={(event) => {
                                   if (event.target.files) setFile(event.target.files[0])
                                   event.target.value = ''
                               }}/>
                        <label className={style.label_button} htmlFor="letter_file_sed"> Прикрепите документ</label>
                    </Col>
                </Row>
                <Row className={'mb-3'}>
                    <Col lg={12}>
                        <div className={style.col_form}>
                            <DialogTitle>
                                Прикрепленные файлы
                            </DialogTitle>
                            <DialogContent dividers>
                                <Row className="g-4">
                                    {Array.from(data.files).map(file => (
                                        <Col xl={4} lg={4} md={6} xxl={3}>
                                            <Card style={{width: '15rem'}}>
                                                <Card.Img style={{alignSelf: "center", width: "64px", height: "74px", paddingTop:"10px"}}
                                                          variant="top" src={checkFileType(file.name)}/>
                                                <Card.Body>
                                                    <Card.Title>{file.name}</Card.Title>
                                                </Card.Body>
                                                <Card.Footer style={{display:"flex",justifyContent:"center"}}>
                                                    <Row className="g-4">
                                                        {file.name.split(".")[1] !== "sig" && (
                                                            <Button variant="primary" onClick={() => {
                                                                if (cert) setSignFile(file)
                                                            }}>Подписать документ</Button>
                                                        )}
                                                        <Button variant="danger" onClick={() => {
                                                            deleteFiles(file.name)
                                                        }}>Удалить</Button>
                                                    </Row>
                                                </Card.Footer>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </DialogContent>
                        </div>
                    </Col>
                </Row>
                <Row className={'column-gap-3'}>
                    <ButtonWithicon disable={disable} handle={() => {
                        sendLetterToLKK()
                    }}>Сохранить письмо
                    </ButtonWithicon>
                    <ButtonWithicon handle={()=>{
                        deleteIncoming(id_letter)
                    }}>
                        {data.isDeleted ? "Восстановить письмо": "Удалить письмо"}
                    </ButtonWithicon>
                </Row>



            </Container>
        </>
    );
}

export default UpdateLetterForm;