import {useEffect, useState} from 'react';
import instanceServerApi from "../../AxiosInstanse/serverApi";
import NewsCard from "../../components/start_page_components/NewsCard";
import style from "../start_page/start_page.module.scss";


function AllNewsList() {
    const [news,setNews] = useState([])

    const handleDeleteNews =(id)=> {
        instanceServerApi.delete("/api/admin/other/delete",{
            data:{
                "table": "news",
                "id": id,
                "isDeleted": true
            }
        })
    }

    useEffect(()=>{
        instanceServerApi.get('/api/other/news', {
            params:{
                body:false,
                sort:{"date":"desc"}
            }
        }).then(res=>{
            if(Array.isArray(res.data.response)) setNews(res.data.response)
        }).catch(err=>console.log(err))
    },[])

    return (
        <>
            <h2 className={style.h2}>Новости</h2>
            {news.length > 0 && (
                <div className={style.main_news}>
                    {news.map(item=>(
                    <div style={{position:"relative"}}>
                        {!item.isDeleted && (
                            <button style={{position:"absolute",right:'10px',bottom:"10px",zIndex:"100"}} onClick={(event)=>{
                                event.stopPropagation()
                                handleDeleteNews(item.id)
                            }}>Удалить</button>
                        )}
                        <NewsCard img={item.image} date={item.date} title={item.title} titleWeb={item.url} />
                    </div>    
                    ))}
                </div>
            )}
        </>

    );
}

export default AllNewsList;