import {useContext} from 'react'
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import {LoadingDataContext} from "../../context/LoadingDataProvider";
import PropTypes from "prop-types";


function LoadingDataAlert({text,severity, vertical, horizontal, autoHideDuration, ...props}) {

    const {load,endLoading} = useContext(LoadingDataContext)


    return (
        <>
            {load && (
                <Snackbar onClose={endLoading}
                          autoHideDuration={autoHideDuration}
                          anchorOrigin={{ vertical: vertical,  horizontal:horizontal }}
                          open={true}>
                    <MuiAlert
                        onClose={endLoading}
                        elevation={6}
                        variant="filled"
                        severity={severity}
                    >
                        {text}
                    </MuiAlert>
                </Snackbar>
            )}
        </>
    );
}

LoadingDataAlert.defaultProps = {
    text: "Идет загрузка...",
    autoHideDuration: null,
    severity: "warning",
    vertical: "top",
    horizontal: "center"

}
LoadingDataAlert.propsType ={
    text: PropTypes.string,
    autoHideDuration: PropTypes.number,
    severity: PropTypes.string,
    vertical: PropTypes.string,
    horizontal: PropTypes.string,
}


export default LoadingDataAlert;