import instanceServerApi from "../AxiosInstanse/serverApi";
import { useState,useLayoutEffect } from "react";


export function useBalance(inn) {
    const [balance, setBalance] = useState(false);
    useLayoutEffect(() => {
        instanceServerApi.post(`/api/profile/balance`,{
            method: "getdebtsql",
            inn: inn
        }).then(res=>{
            if(res.data.response !== 0 && !Array.isArray(res.data.response)) setBalance(res.data.response)
        }).catch(err=>{console.log("err for balance")})

    }, []);
    return balance;
}