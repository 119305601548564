import React, {Suspense} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';

import NotFound from "../components/NotFound/NotFound";
const IlsarRace = React.lazy(()=> import('../components/Ilsar/IlsarRace'))
const MobileRaceIlsarView = React.lazy(()=> import('../components/Ilsar/IlsarView/MobileRaceView'))
const IlsarWorkerRoute = () => {
    return (
           <Suspense fallback={<div>Загрузка</div>}>
                <Switch>
                    <Route exact path='/dashboard/ilsar/race' component={IlsarRace} />
                    <Route path='/dashboard/ilsar/race-view/:id' component={MobileRaceIlsarView} />
                    <Route path='*' component={NotFound}/>
                </Switch>
           </Suspense>
    );
};

export default IlsarWorkerRoute;