import ccpa from "crypto-pro-cadesplugin";

function dataURItoBlob(dataURI, callback) {
    const byteString = atob(dataURI);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    const bb = new Blob([ab]);
    return bb;
}

async function downloadDogovorSign(setSignatureBase64,cert,letter,letterName="договор на информационные услуги") {

    if(cert) {
        const certsApi = await ccpa();
         await certsApi.signBase64(cert, letter).then((sign)=>{
            setSignatureBase64(sign, `Подпись ${letterName}.sig`)
            // const byteCharacters = atob(sign);
            const blob = dataURItoBlob(sign)
            // let blob = new Blob([byteCharacters],{ type: "application/pdf"}); // Затем создайте объект blob и установите тип файла для excel
            let blobURL = window.URL.createObjectURL(blob); // Затем создайте доступный URL
            let tempLink = document.createElement('a'); // Создать тег для загрузки
            tempLink.style.display = 'none';
            tempLink.href = blobURL;
            tempLink.setAttribute('download', `Подпись ${letterName}.sig`);
            if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank');
            }
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }).catch(err=> {
             console.log(err)
             alert(err)
         });

    }
}

export  function arrayBufferToBase64( buffer ) {
    let binary = '';
    const bytes = new Uint8Array( buffer );
    const len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}

export default downloadDogovorSign