import {create} from 'zustand'
import {immer} from "zustand/middleware/immer";

export  const useSedECPStore = create(immer((set,get)=>({
    dogovorSettlement: "",
    fio: "",
    file: null,
    fileName: "",
    signature: null,
    signatureName:"",
    setFileBase64: (data,name="Договор на информационные услуги")=>set(state=>{
        state.file = data
        state.fileName = name
    }),

    setSignatureBase64: (data, name="Подпись документа")=>set(state=>{
        state.signature = data
        state.signatureName = name
    }),

    setDogovorSettlement: (data) => set(state=>{
        state.dogovorSettlement = data
    }),
    setPropertyState : (data,value) => set(state=>{
        state[value] = data
    }),

    setDefaultState: ()=> set(
         { dogovorSettlement: "",
                  fio:"",
                  file: null,
                  fileName: "",
                  signature: null,
                  signatureName:""
        }
    )


})))