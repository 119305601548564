export  const roles = {
    client: "CLIENT",
    manager: "MANAGER",
    mainClient: "MAIN_CLIENT",
    admin: "ADMIN",
    driver: "DRIVER",
    ilsar: "ILSAR",
    wagon: "WAGON",
    admin_wagon: "ADMIN_WAGON",
    admin_ktsp_incoming:"KTSP_ADMIN"
}