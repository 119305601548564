import './style.scss'
import {setCookie} from '../../lib/utils/cookies'
import ButtonWithicon from '../shared/ButtonWithicon'

const handleSetCookie = () =>{
    setCookie("submit_cookie",true,365)
    document.getElementById('cookies_submit_form').style.display = 'none'
}

function CookiesSubmit() {
  return (
    <div className='cookies_form' id='cookies_submit_form'>
        <div>
            <p>
                МЫ ИСПОЛЬЗУЕМ COOKIE
            </p>
            <p>
                Продолжая использовать наш сайт, вы даете согласие на обработку файлов cookie, пользовательских данных (сведения о местоположении; тип и версия ОС; тип и версия Браузера; тип устройства и разрешение его экрана; источник откуда пришел на сайт пользователь; с какого сайта или по какой рекламе; язык ОС и Браузера; какие страницы открывает и на какие кнопки нажимает пользователь; ip-адрес) в целях функционирования сайта, проведения ретаргетинга и проведения статистических исследований и обзоров. Если вы не хотите, чтобы ваши данные обрабатывались, покиньте сайт.
            </p>
        </div>
        <div>
            <ButtonWithicon width={"250px"} handle={handleSetCookie}>
                Принять
            </ButtonWithicon>
        </div>
        
        
    </div>
  )
}

export default CookiesSubmit