export default function createPDF(props) {
    const {company_name,firstNameRod, lastNameRod, secondNameRod, role, osnovanie, INN} = props
  
    return {
        content :[
            {   stack:[
                {   alignment: 'justify',
                    text: 'Закрытое акционерное общество «Контейнерный терминал Санкт-Петербург» (ЗАО «КТСП»),именуемое',
                    margin:[17,0,0,0]
                },
                {   
                    alignment: 'justify',
                    text: `в дальнейшем «Исполнитель», в лице Коммерческого директора Мякинен Яны Павловны, действующей на основании Доверенности № Д-КТСП-01.09.23-Г/0074-1 от 01.09.2023 года, с одной стороны, и   ${company_name}(ИНН ${INN}) именуемое в дальнейшем «Заказчик», в лице ${role} ${lastNameRod} ${firstNameRod} ${secondNameRod}, действующего на основании ${osnovanie}, с другой стороны, далее также совместно именуемые – «Стороны», заключили настоящий Договор о нижеследующем:`
                }
            ]
            }
        ],
        styles:{
            header:{
                fontSize: 11
            }
        },
        defaultStyle: {
            fontSize: 11,
            lineHeight: 1.1,
            font: 'Times'
              
        }
    }
}
