import {useContext} from 'react';
import {useDispatch, useSelector} from "react-redux"
import style from "../../Dashboard/UserAccaunt/userInfoStyle.module.scss"
import Avatar from "@material-ui/core/Avatar"
import {UserContext} from "../../context/UserContext"
import {ReactSVG} from "react-svg"
import {makeStyles} from '@material-ui/core/styles';
import ButtonWithicon from "../shared/ButtonWithicon"
import {Link} from "react-router-dom"
import {SetUserInfoPopup} from "../../redux/StateOfStyleObjects"
import DialogContainer from "./DialogContainer"
import userStyle from "./popup.module.scss"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(20),
        height: theme.spacing(20),
    },
}));


const UserInfoPopup = (props) => {
    const {userInfo, logout} = useContext(UserContext)
    const userInfoPopup = useSelector(state => state.styleState.userInfoPopup)
    const dispatch = useDispatch()
    const classes = useStyles();

    return (
        <DialogContainer popupOpen={userInfoPopup} SetPopup={SetUserInfoPopup}>
            <div className={userStyle.userInfo_container}>
                <div className={style.avatar_line}>
                    <Avatar alt="Remy Sharp" src={process.env.REACT_APP_HTTP_SERVER + "/" + userInfo.avatar} className={classes.large}>A</Avatar>
                    <div className={userStyle.userInfo_helpers}>
                        <Link to={{
                            pathname: "/dashboard/user"
                        }}>
                            <ReactSVG src='/icons/ico_pen.svg'/>
                        </Link>
                        <ReactSVG src='/icons/ico_logout.svg' onClick={() => {
                            logout()
                        }}/>
                    </div>
                </div>
                <div>
                    <p className={style.name_p}> {userInfo.lastName} {userInfo.firstName} {userInfo.secondName}</p>
                    <p className={userStyle.userInfo_company}> {userInfo.company.name}</p>
                    <div className={userStyle.userInfo_phone_email}>
                        <p>{userInfo.email}</p>
                        <p>+{userInfo?.phoneNumber}</p>
                    </div>

                    <div className={userStyle.userInfo_inn_kpp}>
                        <p className={style.name_p_opacity}>ИНН: {userInfo.company?.inn} </p>
                        <p className={style.name_p_opacity}>КПП: {userInfo.company?.kpp} </p>
                    </div>
                    <div className={userStyle.userInfo_documents}>
                        <div style={{
                            height: "52px",
                            width: "52px",
                            backgroundColor: "#EFEFEF",
                            display: "flex",
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: '30px'
                        }}>
                            <ReactSVG src={'/icons/ico_doc.svg'}/>
                        </div>
                        <p style={{maxWidth: '350px', height: "52px"}}>
                            Документы на доверенность вести официальную переписку от лица <br/> <span
                            style={{fontWeight: '500'}}>{userInfo.company.name}</span>
                        </p>
                    </div>
                </div>
                <div className={userStyle.userInfo_change}>
                    <Link onClick={() => {
                        dispatch(SetUserInfoPopup(!userInfoPopup))
                    }} to={{
                        pathname: "/dashboard/user"
                    }}>
                        Редакировать профиль
                    </Link>
                    <Link onClick={() => {
                        dispatch(SetUserInfoPopup(!userInfoPopup))
                    }} to={{
                        pathname: "/dashboard/user"
                    }}>
                        Изменить пароль
                    </Link>
                </div>
                <ButtonWithicon width={"150px"} iconType={"left"} icon={'/icons/ico_logout.svg'} handle={logout}>Выйти</ButtonWithicon>
            </div>
        </DialogContainer>
    )
};

export default UserInfoPopup;