import React, {Suspense, useContext} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import {UserContext} from "../context/UserContext";
import PrivateRoleRoute  from "../components/HOC/PrivateRoleRoute"
import NotFound from "../components/NotFound/NotFound";
import {roles} from "../constants/AuthRole";
import PassesPage from "../Dashboard/Passes/PassesPage";
import UserInfo from "../Dashboard/UserAccaunt/UserInfo";
import ViewNewsById from "../pages/view_news";
import AllNewsList from "../pages/view_news/AllNewsList";
import NotificationsList from "../pages/start_page/NotificationsList";
import SedNewDogovorPage from '../pages/sed_new_dogovor_form'
const AutoVisitView = React.lazy(()=> import('../Dashboard/Autovizit/AutoVisitView'))
const AutoVizitNewRequestConntaier = React.lazy(()=> import('../Dashboard/Autovizit/AutoVizitNewRequestConntaier'))
const AutoVizitNewRequestPage = React.lazy(()=>import('../Dashboard/Autovizit/AutoVizitNewRequestPage'))
const SedContractsContainer = React.lazy(()=> import('../Dashboard/SED/SedContractsContainer'))
const NotFoundSED = React.lazy(()=> import('../Dashboard/Autovizit/NotFoundSED'))
const NotFoundMPSP = React.lazy(()=> import('../Dashboard/Autovizit/NotFoundMPSP'))
const InformalRequestPage = React.lazy(()=> import('../Dashboard/Request/InformalRequestPage'))
const PostsInfoBlock = React.lazy(()=> import('../Dashboard/SED/PostsInfoBlock'))
// const CargoListTable = React.lazy(()=> import('../Dashboard/CargoView/CargoListTable'))
const ApplicationForDocs = React.lazy(()=>import('../pages/applications_for_doc/ApplicationsForDocs'))
const ViewApplicationsForUser = React.lazy(()=>import ('../pages/applications_for_doc/ViewApplicationsForUser'))
const CargoViewPage = React.lazy(()=>import('../pages/cargo'))
const Balance = React.lazy(()=>import('../Dashboard/SED/Balance'))
const ClientWagonView = React.lazy(()=>import('../pages/wagon_view_for_client'))
const ClientWagonPage = React.lazy(()=>import('../pages/client_wagons'))
const StartPage = React.lazy(()=>import('../pages/start_page'))
const Konversta = React.lazy(()=>import('../pages/ktsp_konversta'))
const SeaPortKonversta = React.lazy(()=>import('../pages/seaport_konversta'))
const LetterCreateForm = React.lazy(()=>import('../pages/letter_create'))
const IlsarUPK = React.lazy(()=>import('../pages/ilsar_upk'))
const MainPost = React.lazy(()=>import('../pages/sed_post/MainPost'))
const CreateLetterLKKForm = React.lazy(()=>import('../pages/create_letter_in_lkk'))

const ClientRoute = () => {
    const {userInfo,solvoToken} = useContext(UserContext)
    return (
        <Suspense fallback={<div>...Загрузка</div>}>
            <Switch>
                <PrivateRoleRoute  exact path='/dashboard' component={StartPage} role={[roles.client, roles.mainClient]}/>
                {/*<PrivateRoleRoute  path='/dashboard/questions' component={QuestionsToManagerForm} role={[roles.client, roles.mainClient]}/>*/}
                {/* <PrivateRoleRoute exact path='/dashboard/questions/list' component={QuestionsClientList} role={[roles.client, roles.mainClient]}/> */}
                <PrivateRoleRoute  path='/dashboard/contracts/list' component={SedContractsContainer} role={[roles.client, roles.mainClient]}/>
                <PrivateRoleRoute exact path='/dashboard/autovizit/create-request' component={AutoVizitNewRequestConntaier} role={[roles.client, roles.mainClient]}/>
                {userInfo.ilsarId && (
                    <PrivateRoleRoute path='/dashboard/autovizit/create-request/new/:id' component={AutoVizitNewRequestPage} role={[roles.client, roles.mainClient]}/>
                )}
                <PrivateRoleRoute   path='/dashboard/passes' component={PassesPage} role={[roles.client, roles.mainClient]}/>
                {/*<PrivateRoleRoute  path='/dashboard/requests' component={RequestPageContainer} role={[roles.client, roles.mainClient]} />*/}
                <PrivateRoleRoute  path='/dashboard/ilsar/race-view/:id' component={AutoVisitView} role={[roles.client, roles.mainClient]} />
                {/*<PrivateRoleRoute path='/dashboard/autovizit/list' component={CargoListTable} role={[roles.client, roles.mainClient]} />*/}
                <PrivateRoleRoute path='/dashboard/cargo' component={CargoViewPage} role={[roles.client, roles.mainClient]} />
                {/*<PrivateRoleRoute path='/dashboard/requests/official' component={PostsInfoBlock} role={[roles.client, roles.mainClient]}/>*/}
                <PrivateRoleRoute path='/dashboard/requests/official' component={MainPost} role={[roles.client, roles.mainClient]}/>
                <PrivateRoleRoute path='/dashboard/requests/unofficial' component={InformalRequestPage} role={[roles.client, roles.mainClient]}/>
                  <PrivateRoleRoute path='/dashboard/create-applications-docs' component={ApplicationForDocs} />
                  {/*<PrivateRoleRoute path='/dashboard/view-applications-docs' component={ViewApplicationsForUser} />*/}
                 <PrivateRoleRoute path='/dashboard/wagon_view_client/:id' component={ClientWagonView} />
                 <PrivateRoleRoute path='/dashboard/wagons_client' component={ClientWagonPage} />
                 <PrivateRoleRoute path='/dashboard/balance' component={Balance} role={[roles.client, roles.mainClient]}/>
                 <PrivateRoleRoute exact path='/dashboard/news/:id' component={ViewNewsById}  />
                 <PrivateRoleRoute exact path='/dashboard/news' component={AllNewsList}  />
                 <PrivateRoleRoute exact path='/dashboard/notifications_list' component={NotificationsList}  />
                 <PrivateRoleRoute exact path='/dashboard/sed/new_dogovor' component={SedNewDogovorPage}  />
                 <PrivateRoleRoute exact path='/dashboard/requests/create-official' component={LetterCreateForm}  />
                 <PrivateRoleRoute exact path='/dashboard/sed/create_incoming_sed' component={CreateLetterLKKForm}  />
                <PrivateRoleRoute  path='/dashboard/ilsar_upk' component={IlsarUPK} />
                {!solvoToken && (
                    <PrivateRoleRoute path='/dashboard/solvo-not-found' component={NotFoundSED} role={[roles.client, roles.mainClient]}/>
                )}
                {userInfo.ilsarId === null && (
                    <PrivateRoleRoute path='/dashboard/mpsp-not-found' component={NotFoundMPSP} role={[roles.client, roles.mainClient]}/>
                )}
                <PrivateRoleRoute  strict path='/dashboard/konversta-ktsp' component={Konversta}/>
                <PrivateRoleRoute  strict path='/dashboard/konversta-mpsp' component={SeaPortKonversta}/>
                <Route  path='/dashboard/user' component={UserInfo}></Route>
                <Route path='*' component={NotFound}/>
            </Switch>
        </Suspense>
    );
};

export default ClientRoute;