import React from 'react';
import {Link} from 'react-router-dom'
import style from './passes.module.scss'
import {useHistory} from "react-router-dom";

const PassesPage = () => {
    function windowBlank(link) {
        const newWindow = window.open(link);
        newWindow.opener = null;
    }

    const history = useHistory()

    return (
        <div className={style.bg}>
            <p className={style.title}>Оформление пропусков осуществляется через сторонний сервис</p>
            <p style={{marginBottom: "30px"}} className={style.title}>Выберите ваш терминал</p>
            <div className={style.choice_terminal}>
                <div onClick={()=>{
                    windowBlank('https://konversta.com/ru/terminalspb')
                }}>
                    <img src="/terminalspb.png" alt="ктсп"/>
                </div>
                <div onClick={()=>{
                    windowBlank('https://konversta.com/ru/seaportspb/')
                }} className={style.loginpage_img_flex}>
                    <img src="/seaportspb.png" alt="мпсп"/>
                    <img style={{height:"auto"}} src="/text.png" alt="text"/>
                </div>
            </div>
        </div>
    );
};

export default PassesPage;