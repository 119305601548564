export const SET_ROLLUP_MENU = "SET_ROLLUP_MENU"
export const SET_TOKEN_AUTOVIZIT = "SET_TOKEN_AUTO"
export const SET_USER_INFO_POPUP = "SET_USER_INFO_POPUP"
export const SET_CREATE_TASK_POPUP = "SET_CREATE_TASK_POPUP"
export const SET_DEFAULT_STATE = "SET_DEFAULT_STATE"
export const SET_CHAT_POPUP = "SET_CHAT_POPUP"
export const SET_SETTINGS_POPUP = "SET_SETTINGS_POPUP"
export const SET_MENU_ITEM_CHANGE ="SET_MENU_ITEM_CHANGE"

export const defaultState = {
    rollup: false,
    userInfoPopup: false,
    createTaskPopup: false,
    chatPopup: false,
    settingsPopup: false,
    tokenAutoVizit: "",
    menuItemChange: false
}



const  StateOfStyleObject = (
    state = defaultState,
    action
) => {
    switch (action.type) {
        case SET_ROLLUP_MENU: {
            return {
                ...state,
                rollup: action.payload
            }
        }
        case SET_TOKEN_AUTOVIZIT:{
            return {
                ...state,
                tokenAutoVizit: action.payload
            }
        }
        case SET_USER_INFO_POPUP:{
            return {
                ...state,
                userInfoPopup: action.payload
            }
        }
        case SET_CREATE_TASK_POPUP:{
            return {
                ...state,
                createTaskPopup: action.payload
            }
        }
        case SET_CHAT_POPUP:{
            return {
                ...state,
                chatPopup: action.payload
            }
        }
        case SET_DEFAULT_STATE:{
            state = defaultState
            return {
                state
            }
        }
        case SET_SETTINGS_POPUP:{
            return {
                ...state,
                settingsPopup: action.payload
            }
        }
        case SET_MENU_ITEM_CHANGE:{
            return {
                ...state,
                menuItemChange: action.payload
            }
        }
        default:
            return {...state}
    }
}

export const SetMenuItemChange = (item) =>{
    return {type: SET_MENU_ITEM_CHANGE, payload: item}
}

export const SetSettingsPopup = (rollup) =>{
    return {type: SET_SETTINGS_POPUP, payload: rollup}
}

export const SetChatPopup = (rollup)=>{
    return {type: SET_CHAT_POPUP, payload: rollup}
}

export const SetCreateTaskPopup = (rollup)=>{
    return {type: SET_CREATE_TASK_POPUP, payload: rollup}
}

export const SetRollupMenu = (rollup) => {
    return {type: SET_ROLLUP_MENU, payload: rollup}
}

export const SetTokenAutoVizit = (token) => {
    return {type: SET_TOKEN_AUTOVIZIT, payload: token}
}

export const SetUserInfoPopup = (popup) => {
    return {type: SET_USER_INFO_POPUP, payload: popup}
}


export default StateOfStyleObject;