import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from "../../context/UserContext"
import InputSample from "../../components/shared/InputSample"
import Avatar from "@material-ui/core/Avatar"
import style from './userInfoStyle.module.scss'
import {makeStyles} from "@material-ui/core"
import DisabledInputSample from "../../components/shared/DisabledInputSample"
import IconButton from "@material-ui/core/IconButton"
import {ReactSVG} from "react-svg"
import PhoneInput from 'react-phone-input-2'
import { uploadFileAvatar } from '../../lib/services/uploadFiles';
import UploadFileUser from './UploadFileUser';
import BitrixUserController from './BitrixUserController';
import FilesUser from './FilesUser';
import {useDispatch} from "react-redux"
import {setAutomobileDirectory,setGeneralInfoFromILSAR} from "../../redux/asyncActions"

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(20),
        height: theme.spacing(20),
    },
}));



const UserInfo = () => {
    const dispatch = useDispatch()
    const { userInfo} = useContext(UserContext)
    const [infoProfile, setInfoProfile] = useState({})
    const [file, setFile] = useState(null)
    const [fileURL, setFileURL] = useState(null)
   
    const classes = useStyles();

    const handleUpdateILSAR = ()=>{
        dispatch(setAutomobileDirectory())
        dispatch(setGeneralInfoFromILSAR())
    }
    
   
    useEffect(() => {
        setFileURL(process.env.REACT_APP_HTTP_SERVER +"/" +  userInfo.avatar)
       
    }, [])
    return (
        <section className={style.section}>
            <p>Информация о профиле</p>

            <div className={style.userInfo_container__main}>
                <div className={style.fullname_container}>
                    <div className={style.fullname_container_inputController}>
                        <DisabledInputSample value={userInfo.lastName}  placeholder={userInfo.lastName}
                                     label={"Фамилия"} width={"300px"}/>
                        <DisabledInputSample value={userInfo.firstName}  placeholder={userInfo.firstName}
                                     label={"Имя"} width={"300px"}/>
                        <DisabledInputSample value={userInfo.secondName} placeholder={userInfo.secondName}
                                     label={"Отчество"} width={"300px"}/>
                    </div>
                    <div className={style.flex_container_two_items}>
                        <DisabledInputSample label={'ИНН'} value={userInfo.company.inn} width={'120px'}/>
                        <DisabledInputSample label={'КПП'} value={userInfo.company.kpp} width={'120px'}/>
                    </div>
                    <div style={{width: "100%"}}>
                        <InputSample width={"100%"} label={"Email"} placeholder={userInfo.email}/>
                        <PhoneInput
                            containerStyle={{marginBottom: "30px"}}
                            onChange={(value => setInfoProfile(state => ({...state, phoneNumber: value})))}
                            value={userInfo.phoneNumber}
                            inputStyle={{borderRadius: "0px", height: "38px"}}
                            country={'ru'}
                        />
                        
                    </div>
                    <FilesUser/>
                    </div>
                  
                <div>
                <div className={style.fullname_container_profilePhoto}>
                    <div>
                        <Avatar alt="Remy Sharp" src={fileURL !== null ? fileURL : '/ico_user.png'}
                                className={classes.large}/>
                    </div>
                    <input style={{display: "none"}} name="files_photo" id="files_photo" type="file" onChange={(e) => {
                        setFile(e.target.files[0])
                    }}/>
                    <label htmlFor="files_photo" className={style.button_add_profile_photo}>
                        <IconButton id="files_photo" color="primary" aria-label="upload files_photo" component="span">
                            <ReactSVG src='/icons/ico_photo.svg'/>
                        </IconButton>
                    </label>
                    <ReactSVG className={style.fullname_container_deleteUserPhoto} src='/icon/cross_big.svg' onClick={()=>{setFile(null)}}/>
                    {file !== null && (
                        <div>
                            <Avatar src={URL.createObjectURL(file)} className={classes.large}/>
                        </div>
                    )}
                    {file && (
                        <ReactSVG  onClick={() => {
                            uploadFileAvatar(file,setFileURL)
                        }} src='/icons/ico_save.svg'/>
                    )}
                   
                </div>
                    <div style={{display: "flex", alignItems: "center",columnGap:"20px"}}>
                        <span>Обновить данные для создания рейсов ИЛСАР</span>
                        <ReactSVG src='/icons/ico_refresh.svg' className={style.svg} onClick={handleUpdateILSAR}/>
                    </div>
                  
                   <UploadFileUser />
                   <BitrixUserController/>
                </div>
            </div>
           
        </section>
    );
};

export default UserInfo;