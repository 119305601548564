import {useContext} from 'react';
import {UserContext} from "../../context/UserContext"
import style from './layout.module.scss'
import {useSelector} from "react-redux"
import Header from "../../components/Header/Header"
import NewGeneralMenu from "../../components/Menu/NewGeneralMenu"
import DashboardContent from "../../Dashboard/DashboardContent"
import PopupContainer from "../../components/Popup/PopupContainer"
import AlertExcel from '../../components/alerts/AlertExcel';
import {roles} from "../../constants/AuthRole";
import LoadingDataProvider from "../../context/LoadingDataProvider";
import ExportExcelProvider from "../../context/ExportExcelContext";
import PreviewALerts from "../../components/preview_alert/PreviewALerts";

const IlsarWorkStyle = {
    marginLeft : "0px",
    margin: "0 auto",
    padding: "0"
}



const Layout = () => {
    const {userInfo,loginUser} = useContext(UserContext)
    const styleLayout =  [roles.ilsar, roles.wagon, roles.admin_wagon].includes(userInfo.role) ? IlsarWorkStyle : {}
    const rollup = useSelector(state => state.styleState.rollup)


    if (loginUser) {
        return (
            <div className={style.dashboardContainer}>
                <div>
                    <PopupContainer/>
                </div>
                <header className={style.dashboardHeader}>
                    <Header/>
                </header>
                <section className={style.dashboardSection}>
                    { ![roles.ilsar, roles.wagon, roles.admin_wagon].includes(userInfo.role) && (
                        <NewGeneralMenu role={userInfo.role}/>
                    )}
                    <LoadingDataProvider>
                        <ExportExcelProvider>
                                <main  className={!rollup ? style.dashboardMain_content : style.dashboardMain_content_mini} style={styleLayout}>
                                    <PreviewALerts />
                                    <AlertExcel/>
                                    <DashboardContent/>
                                </main>
                        </ExportExcelProvider>
                    </LoadingDataProvider>
                </section>
            </div>
        )
    } else return (
        <div>
            Загрузка
        </div>
    )
};

export default Layout;