import PropTypes from 'prop-types'
import './alert_style.scss'

const AlertComponent = ({text}) => {
  return (
    <div className="alert_custom">
        {text} 
        <div className="ldsring"><div></div><div></div><div></div><div></div></div>
    </div>
  )
}



AlertComponent.defaultProps ={
    text: "Это сообщение алерт"
}

AlertComponent.propsType ={
    text: PropTypes.string,
}

export default AlertComponent