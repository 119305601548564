export const fontsfamily = [
    {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontDisplay: 'swap',
        fontWeight: 400,
        src: `url(./Font/Montserrat-Medium.ttf) format('truetype')`
    },
    {
        fontFamily: 'Montserrat',
        fontDisplay: 'swap',
        fontWeight: 300,
        src: `url(./Font/Montserrat-Light.ttf) format('truetype')`
    },
    {
        fontFamily: 'Montserrat',
        fontDisplay: 'swap',
        fontWeight: 500,
        src: `url(./Font/Montserrat-SemiBold.ttf) format('truetype')`
    },
    {
        fontFamily: 'Montserrat',
        fontDisplay: 'swap',
        fontWeight: 700,
        src: `url(./Font/Montserrat-Bold.ttf) format('truetype')`
    }

]