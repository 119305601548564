import {createContext, useState,useCallback} from 'react';



export const AlertContext = createContext({})

const AlertProvider = ({children}) => {

    const [isOpen, setIsOpen] = useState(false)

    const [propsAlert, setPropsAlert] = useState({
        anchor: {
            vertical: 'top',  
            horizontal:'center'
        },
        autoHideDuration: null,
        severity: "warning",
        text: 'Идет формирование Excel документа'
    })

    const openAlert = useCallback(()=> setIsOpen(true))

    const closeAlert = useCallback(()=> setIsOpen(false))

    return (
        <AlertContext.Provider value={{ isOpen, openAlert, closeAlert, propsAlert, setPropsAlert}}>
            {children}
        </AlertContext.Provider>
    );
}


export default AlertProvider

