import React, {useContext, useState} from 'react';
import instanceServerApi from "../../../AxiosInstanse/serverApi";
import {useSedECPStore} from "../store/sed_ecp_store";
import {UserContext} from "../../../context/UserContext";
import ButtonWithicon from "../../../components/shared/ButtonWithicon";
import style from "../style.module.scss"
import {ReactSVG} from "react-svg";
import TitleCard from "../../../components/FontComponent/TitleCard";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import {v4 as uuidv4} from "uuid"
import LoadingDataAlert from "../../../components/alerts/LoadingDataAlert";
import {LoadingDataContext} from "../../../context/LoadingDataProvider";
import AlertComponent from "../../../components/Alert/AlertComponent";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";




const societyList = [
    // {
    //     label:  "АО \"Морской порт Санкт-Петербург\"",
    //     value: "8110"
    // },
    // {
    //     label:  "ООО «Универсальный перегрузочный комплекс»",
    //     value: "8120"
    // },
    {
        label:  "ЗАО \"Контейнерный терминал Санкт-Петербург\"",
        value: "8130"
    },
    // {
    //     label:  "АО \"Туапсинский морской торговый порт\"",
    //     value: "8140"
    // },
    // {
    //     label:  "АО \"Туапсинский зерновой терминал\"",
    //     value: "8150"
    // }
]


function ConfirmationForm(props) {
    const {userInfo} = useContext(UserContext)
    const fileName = useSedECPStore(state=>state.fileName)
    const fileBase64 = useSedECPStore(state=>state.file)
    const dogovorSettlement = useSedECPStore(state=>state.dogovorSettlement)
    const signature64 = useSedECPStore(state=>state.signature)
    const setDefaultState = useSedECPStore(state=>state.setDefaultState)
    const [society, setSociety] = useState('8130')
    const [success, setSuccess] = useState(null)
    const [loading, setLoading] = useState(false)
    const {startLoading} = useContext(LoadingDataContext)
    const sendFilesToSed = ()=>{
       
        const sendData = {
            "ContractNumber": fileName,
            "ContractDate": new Date().toISOString(),
            "IDCompanyInSAP": society,
            "IDContragentInSAP": userInfo.company.id,
            "ContractorSettlementAccount": dogovorSettlement,
            "UserLogin": `uclh\\i.lizunova`,
            "UniqueContractIdentifierLCC": "LKK_" + uuidv4(),
            "Document": {
                "Name": "ДОГОВОР № " + fileName.replace('/',".") + ".pdf",
                "Content": fileBase64
            },
            "Sign": {
                "Name": "podpis_" + fileName.replace('/',".") + ".sig",
                "Content": signature64
            }
        }

        const separateLines = sendData.Sign.Content.split(/\r?\n|\r|\n/g);
        const base64 = separateLines.reduce((accumulator, currentValue)=> accumulator + currentValue,'')
        sendData.Sign.Content = base64
        setLoading(true)

        instanceServerApi.post("/api/services/sed/with_sign/document", sendData).then(res=>{
            instanceServerApi.get('/api/services/sed/documents/last').then(res=>{
                const number_doc = res.data.response[0].number === null ? 1 : Number(res.data.response[0].number) + 1
                instanceServerApi.post('/api/services/sed/documents',{
                    "year": new Date().getFullYear(),
                    "number": number_doc + "",
                    "emails": ``,
                    "post": "Договор на оказание информационных услуг",
                })
            })

            setLoading(false)
            setSuccess(true)
            startLoading(true)
            setDefaultState()
        }).catch(err=>{
            setLoading(false)
            setSuccess(false)
            startLoading(true)
        })
    }


    return (
        <div style={{margin:"20px 0"}}>

            {success === true && (
                <LoadingDataAlert severity='success' autoHideDuration={1500} text='Документ загружен'/>
            )}
            {success === false && (
                <LoadingDataAlert severity='error' autoHideDuration={1500} text='Произошла ошибка при добавлении документа'/>
            )}
            {loading && (
                <Snackbar
                          anchorOrigin={{ vertical: "center",  horizontal:"center"}}
                          open={true}>
                    <MuiAlert
                        elevation={6}
                        variant="filled"
                        severity={'warning'}
                    >
                        Идет отправка документа в СЭД
                    </MuiAlert>
                </Snackbar>
            )}

            <Form.Group as={Col} lg={6}>
                <Form.Label>Выберите порт</Form.Label>
                <Form.Select type='text' value={society} defaultValue={'8130'} onChange={e => {
                    setSociety(e.target.value)
                }}>
                    {societyList.map(item=>(
                        <option value={item.value}>{item.label}</option>
                    ))}

                </Form.Select>
            </Form.Group>
            <TitleCard>Для отправки договора в СЭД необходимо его сформировать и подписать</TitleCard>
            <p className={fileBase64 ? style.required_item__success : style.required_item__false}>
                {fileBase64 ? <ReactSVG src='/icons/ico_ok.svg'  className={style.required_item_svg__success}/> : <ReactSVG src='/icons/ico_cross.svg'  className={style.required_item_svg__false} />}
                Договор сформирован
            </p>
            <p className={signature64 ? style.required_item__success : style.required_item__false}>
                {signature64 ? <ReactSVG src='/icons/ico_ok.svg'  className={style.required_item_svg__success}/> : <ReactSVG src='/icons/ico_cross.svg'  className={style.required_item_svg__false} />}
                Договор подписан
            </p>
            {(signature64 && fileBase64) && (
                <>
                    <ButtonWithicon disable={loading} handle={()=>{
                        sendFilesToSed()
                    }}>Отправить договор в СЭД</ButtonWithicon>
                </>
            )}

        </div>
    );
}

export default ConfirmationForm;