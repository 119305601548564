
import instanceServerApi from "../../AxiosInstanse/serverApi";

export function openSedFile(url) {
    console.log(url)
    const newUrl = url.replaceAll(' ','')
    const type = url.split('.').pop()
    
    instanceServerApi.post('/api/services/sed/download',{
        file: newUrl
    }).then(response=>{
        if(type === "pdf") {
            const fileName = decodeURI(url.split("/").pop())

            const file = new Blob([new Uint8Array(response.data.response.data)], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }
        if(type === "doc" || type === "docx") {
            const fileName = decodeURI(url.split("/").pop())

            let blob = new Blob ([new Uint8Array(response.data.response.data)], {type: "application/msword"}); // Затем создайте объект blob и установите тип файла для excel
            let blobURL = window.URL.createObjectURL(blob); // Затем создайте доступный URL
            let tempLink = document.createElement ('a'); // Создать тег для загрузки
            tempLink.style.display = 'none';
            tempLink.href = blobURL;
            tempLink.setAttribute('download',`${fileName}`);
            if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank');
            }
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(blobURL);
        }
        
    }).catch(err=>{
        alert("Произошла ошибка при загрузке файла")
    })
}