import React, {useEffect} from 'react';
import {helper_text_sed,helper_text_sed_new_dogovor} from "../../lib/utils/sed/helpers_text";
import style from './sedstyle.module.scss'


function SedSignRules({inn,fio}) {
    useEffect(()=>{
       const div =  document.getElementById('sed_sign_rules')
        div.innerHTML = ''
        div.innerHTML += helper_text_sed(inn,fio).text
    },[])

    return (
        <div  className={style.sed_rules_info}>
            <h4>Требования к подписи</h4>
            <div id={'sed_sign_rules'}> </div>
            <p>В случае возникновения вопросов просьба обращаться в тех. поддержку</p>
        </div>
    );
}

export function SedSignRulesForNewDogovor({inn,fio}) {
    useEffect(()=>{
        const div =  document.getElementById('sed_sign_rules')
        div.innerHTML = ''
        div.innerHTML += helper_text_sed_new_dogovor(inn,fio).text
    },[fio])

    return (
        <div  className={style.sed_rules_info}>
            <h4>Требования к подписи</h4>
            <div id={'sed_sign_rules'}> </div>
            <p>В случае возникновения вопросов просьба обращаться в тех. поддержку</p>
        </div>
    );

}

export default SedSignRules;