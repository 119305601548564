import React, {Suspense} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import NotFound from "../components/NotFound/NotFound";
const WagonsPutPage = React.lazy(()=> import('../pages/wagons_put'))
const WagonsListForCheck = React.lazy(()=>import('../pages/wagon_telman_list'))

function WagonRoutes() {
    return (
        <Suspense fallback={<div>Загрузка</div>}>
            <Switch>
                <Route exact path='/dashboard/create_wagons' component={WagonsPutPage} />
                <Route exact path='/dashboard/check_wagons' component={WagonsListForCheck} />
                <Route path='*' component={NotFound}/>
            </Switch>
        </Suspense>
    );
}

export default WagonRoutes;