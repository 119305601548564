import { useContext, useState } from 'react'
import {uploadFileForUser} from '../../lib/services/uploadFiles'
import UploadFileIcon from '../../components/shared/UploadFileIcon'
import { UserContext } from '../../context/UserContext'

const  UploadFileUser = () => {
    const [files,setFiles] = useState(null)
    const {userInfo} = useContext(UserContext)
    return (
        <div>
            Добавить файлы
                       <UploadFileIcon setFile={setFiles}/>
                       {files && (
                        <>
                            <div>
                                {files.name}
                            </div>
                            <button  onClick={()=>{uploadFileForUser(files,userInfo.id)}}>
                                Загрузить
                            </button>
                        </>
                       )}
                       
        </div>
    )
}



export default UploadFileUser